import {call, put, takeLatest, delay} from "redux-saga/effects";
import {FETCH_PAWN_LIST} from "./types";
import API from "core/networks/API";
import {fetchPawnDataListFail, fetchPawnDataListSuccess} from "./actions";
function* fetchDataList(_action: any): any{
    yield delay(150)
    try {
        const response = yield call(() => {
            switch (_action.payload.method) {
                case "POST": return API.post(_action.url, _action.payload);
                case "PUT": return API.put(_action.url, _action.payload);
                case "PATCH": return API.patch(_action.url, _action.payload);
                case "DELETE": return API.delete(_action.url, _action.payload);
                default: return API.get(_action.url, _action.payload);
            }
        });
        const { data } = response;

        if (data?.code === 200) {
            yield put(fetchPawnDataListSuccess(_action.storeName, data.data));
        }else{
            yield put(fetchPawnDataListFail(_action.storeName));
        }
    } catch (error) {
        yield put(fetchPawnDataListFail(_action.storeName));
    }
}

export default function* ContentSaga() {
    yield takeLatest(FETCH_PAWN_LIST, fetchDataList)
}
