import DataSet from "core/components/DataSet";
import { formNameUseStatistic } from "../hooks/useStatistics";
import module from "../module";
import UserStatisticListRowTemplate from "./UserStatisticListRowTemplate";

const MerchantStatisticTable = () => {
  return (
    <>
      <div className="row pt-2">
        <div className="col-12">
          <strong className="fs-5">Merchant statistics</strong>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <DataSet
            idKey={"hidden"}
            filterName={formNameUseStatistic}
            module={module.name}
            columnKey={formNameUseStatistic}
            pagination={false}
            columnTemplate={[
              { label: "ID", key: "id", state: true, sort: false },
              { label: "Phone", key: "phone", state: true, sort: false },
              {
                label: "Display name",
                key: "display_name",
                state: true,
                sort: false,
              },
              {
                label: "Signup at",
                key: "created_at",
                state: true,
                sort: false,
              },
            ]}
            rowTemplate={UserStatisticListRowTemplate}
          />
        </div>
      </div>
    </>
  );
};

export default MerchantStatisticTable;
