import API from 'core/networks/API';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { requestGlobalSettingFail, requestGlobalSettingSuccess } from './actions';
import {API_GLOBAL_CONFIG} from "../api";

function* fetchSetting(): any{
try {
    const response = yield call(() => {
        return API.get(API_GLOBAL_CONFIG, {});
    });
    const { data } = response;
    
    if (data?.code === 200) {
        yield put(requestGlobalSettingSuccess(data));
    }else{
        yield put(requestGlobalSettingFail(data));
    }
} catch (error) {
    yield put(requestGlobalSettingFail(error));
}
}



let sagas: any = []
const sagaModules = require.context('./../../modules/', true, /([^/]+)\/saga.ts$/);
const modules = require.context('./../../modules/', true, /module.ts$/)

modules.keys().forEach((mpath: any) => {
    const moduleJson = modules(mpath).default
    if(moduleJson?.status?.redux && mpath.includes('modules/')){
        const key = mpath.toString().replace('module.ts', 'store/saga.ts');
        const saga = sagaModules(key);
        sagas = [...sagas, (saga.default || saga)];
    }
})


export default function* rootSaga() {
    yield takeLatest("REQUEST_GLOBAL_SETTING", fetchSetting);
    yield all([
        ...(sagas.map((pageSaga: any) => {
            return pageSaga()
        }))
    ]);
}
