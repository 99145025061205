const MessageAttachments = ({ attachments }: any) => {
  return attachments.length ? <div className="mt-3">
    <span>[Attachment]</span>
    {attachments.map((e: any, key: any) => (
      <a key={key} href={e.url.original} target="_blank" className="d-block">
        {e.url.original}
      </a>
    ))}
  </div> : null
}

export default MessageAttachments
