import DashboardLayout from "../../../core/layouts/DashboardLayout";
import {
  formNameStatistics,
  useMerchantStatistic,
  usePostStatistic,
  useStatistic,
  useStatisticParam,
  useTotalStatistic,
  useUserStatistic,
} from "../hooks/useStatistics";

import { setParamsUrl } from "core/utils/helper";
import { useEffect } from "react";
import MerchantStatisticTable from "../components/MerchantStatisticTable";
import OverviewStatistic from "../components/OverviewStatistic";
import PostStatisticTable from "../components/PostStatisticTable";
import StatisticChart from "../components/StatisticChart";
import StatisticFilter from "../components/StatisticFilter";
import StatisticWidgets from "../components/StatisticWidgets";
import UserStatisticTable from "../components/UserStatisticTable";
import module from "../module";
import { useQuery } from "core/hooks/useQuery";

const Dashboard = (props: any) => {
  const [statistic, handleFetchStatistic] = useStatistic({});
  const [totalStatistic, handleFetchTotalStatistic] = useTotalStatistic({});
  const [userStatistic, handleFetchUserStatistic] = useUserStatistic({});
  const [merchantStatistic, handleFetchMerchantStatistic] = useMerchantStatistic({});
  const [postStatistic, handleFetchPostStatistic] = usePostStatistic({});
  const {param: statisticParam, handlePageChange: handlePageChange} = useStatisticParam();
  const queryParams = useQuery();

  console.log(totalStatistic, 'totalStatistic');
  
  useEffect(() => {
    // handleFetchStatistic({});
  }, [])
 
  useEffect(() => {
    console.log('statisticParam', statisticParam);
    setParamsUrl(statisticParam);
    handleFetchStatistic({});
    handleFetchTotalStatistic(statisticParam);
    handleFetchUserStatistic(statisticParam);
    handleFetchMerchantStatistic(statisticParam);
    handleFetchPostStatistic(statisticParam);
  }, [statisticParam.startDate, statisticParam.endDate])

  return (
    <>
      <DashboardLayout currentPage="dashboard/overview" title="Dashboard">
        <div className="overview-container">
          <StatisticWidgets
            dataWidgets={statistic}
          />
          <hr />
          <div className="row content-statics">
            <div className="col-4">
              <div className="row mb-3 pt-2">
                <div className="col-sm-12">
                  <StatisticFilter
                    filterName={module.name}
                    initialValues={{
                      date: statisticParam
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3 pt-2">
                <div className="col-sm-12">
                  <OverviewStatistic
                    dataOverview={totalStatistic}
                  />
                </div>
              </div>
              <div className="row mb-3 pt-2 chart">
                <div className="col-sm-12">
                  <StatisticChart filterName={module.name} initialValues={{}} />
                </div>
              </div>
            </div>
            <div className="col-8">
              <UserStatisticTable queryParams={queryParams}/>
              <MerchantStatisticTable />
              <PostStatisticTable />
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Dashboard;
