import DataSet from "core/components/DataSet"
import DashboardLayout from "core/layouts/DashboardLayout"
import PageFilter from "./PageFilter"
import PageForm from "./PageForm"
import { useBackNavigate, useCustomNavigate } from "core/utils/helper"
import { RouterName } from "core/router"
import API from "core/networks/API"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import {useDispatch, useSelector} from "react-redux"
import {SubmissionError, updateSyncErrors} from "redux-form";
import {useParams} from "react-router-dom";



const UpdatePage = (props: any) => {
    const commonRedux = useSelector((state: any) => state.Common)
    const navigate = useCustomNavigate()
    const dispatch = useDispatch()

    const [formLoading, setFormLoading] = useState(false)

    const handleGoBack = () => {
        navigate(RouterName.CONTENT_PAGE)
    }

    const handleSubmit = async (formData: any) => {
        //console.log("CREAGE PAGE DATA", formData)
        setFormLoading(true)
        const error = await callUpdateCreatePageAPI(parseData(formData));
        //console.log(error)
        if(!error){
            setFormLoading(false)
            toast.success("Thêm trang thành công.")
            navigate(RouterName.CONTENT_PAGE)
        }else{
            setFormLoading(false)
            toast.error("Thêm trang thất bại, vui lòng check các lỗi.")
            throw new SubmissionError({...error})
        }
    }

    const parseData = (formData: any) => {
        let data = formData;
        let template_content = {}
        if(commonRedux.setting?.templates){
            const [template] = commonRedux.setting.templates.filter((item: any) => item.id == formData.template)
            if(template){
                //console.log(48, template)
                if(template.fields.length > 0){
                    for(const field of template.fields){
                        if(formData['template_content_'+field.id]){
                            template_content = {...template_content, [field.id]: data['template_content_'+field.id]}
                            delete data['template_content_'+field.id]
                        }
                    }
                }
            }
        }
        data = {...data, template_content}
        return data;
    }

    const parseValidateError = (errors: any) => {
        let result = {}
        errors.forEach((item: any) => {
            result = {...result, [item.field]: item.error}
        })
        return result;
    }

    const callUpdateCreatePageAPI = (data: any) => {
        return new Promise(async (resolve, reject) => {
            try{
                const res = await API.put('/cms/v1/content/page/'+params.id, data)
                resolve(false)
            } catch(e: any){
                if(e.response?.data?.errors.length > 0) {
                    resolve(parseValidateError(e.response?.data?.errors))
                }
            }
        })
    }

    const [data, setData] = useState<any>({})
    const params = useParams()
    const callFetchDetailPage = async () => {
        try{
            const res = await API.get(`/cms/v1/content/page`, {id: params?.id})
            //console.log(90, res.data.data)
            setData(res.data.data)
        } catch(e: any){}
    }

    const parseFormData = () => {
        const result: any = {
            title: data.title,
            slug: data.slug.alias,
            template: data.template,
            content: data.content,
            seo_title: data.seo.meta_title,
            seo_description: data.seo.meta_description,
            seo_keyword: data.seo.meta_keywords,
            seo_image: data.seo.meta_image,
            cover_image: data.cover_image,
            featured_image: data.featured_image,
            published_at: data.published_at,
        }
        return result
    }


    useEffect(()=>{
        // toast.success("Thêm trang thành công.")
        callFetchDetailPage()
    }, [])

    return <>
        <DashboardLayout currentPage="content/page" title="Quản lý trang nội dung">
            <h1><span className="go-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i> Quay lại</span> Chỉnh sửa trang nội dung</h1>
            {data.id && <PageForm
                initialValues={parseFormData()}
                loading={formLoading}
                onSubmit={handleSubmit}
                handleSubmitClick={handleSubmit}
                />}
        </DashboardLayout>
    </>
}

export default UpdatePage