import { SkeletonProps } from "core/interface/Skeleton";
import Skeleton from "react-loading-skeleton";

const SkeletonComponent = ({
  width,
  className,
  count,
  height,
  circle,
}: SkeletonProps) => {
  return (
    <Skeleton
      containerClassName={className}
      width={width}
      count={count}
      height={height}
      circle={circle}
    />
  );
};

export default SkeletonComponent;
