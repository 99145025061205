import KDialog from "../Dialog/KDialog";
import FileLibrary from "./FileLibrary";
import {useState} from "react";
import {FileBrowserDialogProps} from "./interfaces";

const FileBrowserDialog = (props: FileBrowserDialogProps) => {
    const [show, setShow] = useState(true)
    const handleSelectFile = (data: any) => {
        setShow(false)
        // //console.log(13, data)
        let returnData: any = null;
        if(props.multi){
            returnData = data
        }else{
            returnData = data[0]
        }
        if(props.onSelect) props.onSelect(returnData)
    }

    const handleClose = () => {
        setShow(false)
        if(props.onClose) props.onClose()
    }

    return <>
        <KDialog
            variant={'iframe'}
            show={show}
            overlay={props.overlay === undefined ? false : props.overlay}
            onCancel={handleClose}
            cancelBtn={"Đóng thư viện"}
        >
            <FileLibrary onSelect={handleSelectFile}/>
        </KDialog>
    </>
}

export default FileBrowserDialog