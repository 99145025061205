import {FETCH_PAWN_LIST, FETCH_PAWN_LIST_FAIL, FETCH_PAWN_LIST_SUCCESS, INIT_PAWN_LIST} from "./types";


const PawnReducer = (
    state: any = {},
    action: any,
): any => {
    switch (action.type) {
        case INIT_PAWN_LIST:
            return {...state, [action.storeName]: {list: {}, loading: false}}
        case FETCH_PAWN_LIST:
            return {...state, [action.storeName]: {list: {}, loading: true}}
        case FETCH_PAWN_LIST_SUCCESS:
            //console.log(action.payload)
            return {...state, [action.storeName]: {...action.payload, loading: false}}
        case FETCH_PAWN_LIST_FAIL:
            return {...state, [action.storeName]: {list: {}, loading: false}}
        default:
            return state;
    }
};

export default PawnReducer