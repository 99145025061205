import React, { useState } from 'react';

import defaultImage from 'core/assets/images/default-image.png'

interface ImageComponentProps {
  src: string;
  defaultImageUrl?: string;
  alt?: string;
  title?: string;
  className?: string;
}

const ImageComponent: React.FC<ImageComponentProps> = ({ className, title, alt, src, defaultImageUrl }) => {
  const [hasError, setHasError] = useState(false);

  const handleImageError = () => {
    // Xử lý lỗi khi ảnh không tải được
    setHasError(true);
  };

  return (
    <>
      {hasError ? (
        // Nếu có lỗi, hiển thị ảnh mặc định
        <img className={className} src={defaultImageUrl || defaultImage} alt={alt || ""} />
      ) : (
        // Nếu không có lỗi, hiển thị ảnh với URL truyền vào
        <img className={className} src={src || defaultImage} alt={alt || ""} title={title|| ""} onError={handleImageError} />
      )}
    </>
  );
};

export default ImageComponent;