import { useConversationDetail } from './../hooks/useConversation'
import { useDownloadMessage } from './../hooks/useDownloadMessage'
import { RouterName, route } from "core/router";

const ConversationInfo = (props: any) => {
  const conversation = useConversationDetail()
  const [ loadingDownload, downloadMessage ]: any = useDownloadMessage(conversation)

  const _downloadMessage = () => {
    downloadMessage()
    // window.open(route(RouterName.DOWNLOAD_CHAT_PAGE, id), '_blank');
  }

  return <div className="conversation-info">
    <div className="h6 mb-5 cursor-pointer d-flex" onClick={_downloadMessage}>
      <i className="fa-solid fa-cloud-arrow-down"></i>
      <label className="text-danger ms-3 cursor-pointer">
        {loadingDownload ? 'Dowloading this conversation content...' : 'Dowload this conversation content'}
      </label>
    </div>
    {conversation.data?.map((e: any, key: any) => (
      <p className="mb-2 d-flex" key={key}>
        <i className="fa-solid fa-circle-info"></i>
        <label className="ms-3">
          View information of {e.shops ? e.shops[0].name : e.name}
        </label>
      </p>
    ))}
    <p className="mb-2 d-flex">
      <i className="fa-solid fa-circle-info"></i>
      <label className="ms-3">View all their transaction (post and bid)</label>
    </p>
  </div>
}

export default ConversationInfo
