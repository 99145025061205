import {numberFormat} from "../../../../core/utils/helper";

const CouponReconciliationStatisticTemplate = (props: any) => {
    //console.log(2, props)
    return <>
        <div className="mb-2">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total used
                            coupons: <strong>{props.data?.total}</strong>
                        </div>

                        <div className="col">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total
                            amount: <strong>{numberFormat(props.data?.total_amount, '.')} VND</strong>
                        </div>
                        <div className="col">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total
                            settled amount: <strong>{numberFormat(props.data?.total_settled_amount, '.')}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default CouponReconciliationStatisticTemplate