import { config } from "core/config/app";
import PageList from "./pages/page_management/PageList";
import CreatePage from "./pages/page_management/PageCreate";
import PageUpdate from "./pages/page_management/PageUpdate";
import CategoryList from "./pages/category_management/CategoryList";

const router =  [
    {
        name: "CONTENT_PAGE",
        auth: true,
        exact: true,
        path: `${config?.baseName}/content/page`,
        element: <PageList />
    },
    {
        name: "CONTENT_PAGE_EDIT",
        auth: true,
        exact: true,
        path: `${config?.baseName}/content/page/:id`,
        element: <PageUpdate />
    },
    {
        name: "CONTENT_PAGE_CREATE",
        auth: true,
        exact: true,
        path: `${config?.baseName}/content/page/create`,
        element: <CreatePage />
    },
    //Categories
    {
        name: "CONTENT_CATEGORY",
        auth: true,
        exact: true,
        path: `${config?.baseName}/content/categories`,
        element: <CategoryList />
    },
]
export default router
