import { config } from "core/config/app";
import Login from "./pages/Login";

const router =  [
    {
        name: "LOGIN",
        auth: false,
        exact: true,
        path: `${config?.baseName}/login`,
        element: <Login />
    },
]
export default router
