import { numberFormat } from "../../../../core/utils/helper";

const CouponStatisticTemplate = (props: any) => {
    //console.log(2, props)
    return <>
        <div className="mb-2">
            <div className="card">
                <div className="card-body">
                    <div className="row mb-2">
                        <div className="col">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total issued
                            coupons: <strong>{numberFormat(props.data?.total || 0)}</strong>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total used
                            coupons: <strong>{numberFormat(props.data?.used || 0)}</strong>
                        </div>
                        <div className="col">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total available
                            coupons: <strong>{numberFormat(props.data?.available || 0)}</strong>
                        </div>
                        <div className="col">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total validating
                            coupons: <strong>{numberFormat(props.data?.validating || 0)}</strong>
                        </div>
                        <div className="col">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total active
                            coupons: <strong>{numberFormat(props.data?.actived || 0)}</strong>
                        </div>
                        <div className="col">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total using
                            coupons: <strong>{numberFormat(props.data?.using || 0)}</strong>
                        </div>
                        <div className="col">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total expired
                            coupons: <strong>{numberFormat(props.data?.expired || 0)}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default CouponStatisticTemplate
