import ConversationFilter from './ConversationFilter'
import ConversationItem from './ConversationItem'
import Loading from './Loading'
import Pagination from "core/components/Pagination";
import useChatHeight from './../hooks/useChatHeight'
import { useConversationParam, useConversation } from './../hooks/useConversation'

const PAGINATE_HEIGHT = 67 // 'px'

const ConversationList = (props: any) => {
  const height = useChatHeight('conversation-group')
  const { param, handlePageChange } = useConversationParam()
  const conversations = useConversation(param)

  return <>
    <div
      className="w-100"
      id="conversation-list"
      style={{height: (height - PAGINATE_HEIGHT) + 'px', overflowY: "auto"}}
    >
      {conversations.loading ? <Loading /> : null}
      {conversations.data?.map((e: any, key: any) => (
        <ConversationItem key={key} conversation={e} />
      ))}
      {!conversations.loading && !conversations.data.length ? (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <div className="text-center">
            <i className="fa-regular fa-comment-alt fa-3x" />
            <div>No data matching the request was found.</div>
          </div>
        </div>
      ) : null}
    </div>

    <div>
      {+conversations.last_page > 1 ? <Pagination
        className={"pagination justify-content-end"}
        total={+conversations.last_page || 0}
        current={+(conversations.current_page || 1) - 1}
        onPageChange={handlePageChange}
      /> : null}
    </div>
  </>
}

export default ConversationList
