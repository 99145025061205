import KeywordComponent from "core/components/KeywordComponent";
import SelectComponent from "core/components/SelectComponent";
import { RouterName } from "core/router";
import { useCustomNavigate } from "core/utils/helper";
import { FormEvent } from "react";
import {Field, InjectedFormProps, reduxForm} from "redux-form";

let formName = 'pageMgmt'
interface CustomProps {

}
const Filter: React.FC<CustomProps & InjectedFormProps<{}, CustomProps>> = (props: any) => {
    const navigate = useCustomNavigate()
    // //console.log(props)
    const { error, handleSubmit, pristine, reset, submitting, dispatch } = props

    const handleFilterSubmit = (e: any) => {
        e.preventDefault()
    }

    const handleSearch = (e: any) => {
        handleSubmit((values: any) => {
            props.onSubmit(values);
        })();
    }

    const handleGoToCreate = () => {
        navigate(RouterName.CONTENT_PAGE_CREATE)
    }
    
    return <form onSubmit={handleFilterSubmit} className="mng-filter">
        <Field
            name="keyword"
            type="hidden"
            disabled={submitting}
            component={KeywordComponent}
            label="Filter by keyword"
            handleDispatch={dispatch}
            formName={formName}
        />

        {/*<Field name="page" type="hidden" component="input"/>*/}
        <button type="button" className="btn btn-search me-2 ms-5" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i> Search</button>
        {/*<button type="button" className="btn btn-create" onClick={handleGoToCreate}><i className="fa-solid fa-plus"></i> Thêm trang</button>*/}
    </form>
}


export default reduxForm<{}, CustomProps>({
    form: formName
})(Filter);