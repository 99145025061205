import { config } from "core/config/app";
import { removeAxiosToken, setAxiosToken } from "core/networks/API";
import { deviceStorage } from "core/utils/devicestorage";

export interface DataState {
    user: any;
    token: string;
    loading: boolean;
    error: Error | null;
    type: string;
}

const initialState: DataState = {
    user: null,
    token: "",
    loading: false,
    error: null,
    type: ""
};

const AuthReducer = (
    state: DataState = initialState,
    action: any,
): DataState => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {...state, type: action.type, loading: true};
        case "REQUEST_LOGIN_SUCCESS":
            deviceStorage[config.appStorage].setItem(config.appPackage+'_X_TOKEN', action.payload.data.token);
            setAxiosToken(action.payload.data.token);
            return {...state, type: action.type, ...action.payload.data, loading: false};
        case "REQUEST_LOGIN_FAIL":
            return {...state, type: action.type, loading: false};
        case "REQUEST_FETCH_AUTH":
            return {...state, type: action.type,  loading: true};
        case "REQUEST_FETCH_AUTH_SUCCESS":
            return {...state, type: action.type, ...action.payload.data, loading: false};
        case "REQUEST_FETCH_AUTH_FAIL":
            removeAxiosToken()
            return {...state, type: action.type, loading: false};
        case "REQUEST_LOGOUT":
            deviceStorage[config.appStorage].removeItem(config.appPackage+'_X_TOKEN');
            removeAxiosToken();
            return {...state, type: action.type, user: null, token: "", loading: false};
        default:
            return state;
    }
};

export default AuthReducer