import {Fragment, useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import SkeletonComponent from "./Skeleton/skeleton";
import ReactPaginate from "react-paginate";
import Pagination from "./Pagination";
import Loading from "./Loading";
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {setDataSetColumns, setPaginate, updateDataSetColumns, updatePaginate} from "core/store/actions";
import {change} from "redux-form";
import _ from "lodash";
import KDialog from "./Dialog/KDialog";

const DataSet = (props: any) => {
    const dispatch = useDispatch();
    const { rowTemplate: RowTemplate } = props;
    const {bulkActionComponent: BulkActionComponent} = props
    const { statisticTemplate: StatisticTemplate } = props;
    const paginateRedux = useSelector((state: any) => {
        if (state.paginate.hasOwnProperty(props.filterName))
            return state.paginate[props.filterName];
        return {
            page: 1,
            page_size: 20
        };
    });

    const [ids, setIds] = useState<any>([]);
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

    const columns = useSelector((state: any) => {
        if (state.Common.columns.hasOwnProperty(props.columnKey))
            return state.Common.columns[props.columnKey];
        return [];
    });
    const activeColumns = useMemo(() => {
        //console.log(35, columns)
        return columns ? columns.filter((item: any) => item.state) : [];
    }, [columns]);

    const resultRedux = useSelector((state: any) => {
        if (state[props.module].hasOwnProperty(props.columnKey))
            return state[props.module][props.columnKey];
        return {
            list: {},
            loading: false
        };
    });

    

    const[_props, setProps] = useState<any>(props)
    // //console.log(columns)

    const renderTableHeader = () => {
        return columns.length > 0 && columns.map((col: any, k: number) => {
            if(col.state) return <th key={k}>{col.label}</th>
        })
    }

    const renderTableBody = () => {
        const idKey = props.idKey || 'id'
        if(resultRedux.loading || !resultRedux.data){
            return <>
            <tr>
                <td colSpan={(activeColumns.length + 2)} className="text-center">
                    <div className="m-5">
                        <Loading type="type3"/>
                    </div>
                </td>
            </tr>
            </>
        }else{
            if(resultRedux.data.length == 0){
                return <tr>
                    <td colSpan={(activeColumns.length + 2)} className="text-center ">
                        <div className="m-5">
                            No data matching the request was found.
                        </div>
                    </td>
                </tr>
            }else{
                return resultRedux.data.map((item: any, k: number) => {
                    return <Fragment key={k}>
                        <RowTemplate
                            onCheck={handleCheckRow}
                            checked={ids.includes(item[idKey])}
                            onDelete={handleConfirmDelete}
                            onAdd={props.onAdd}
                            onEdit={props.onEdit}
                            idKey={idKey}
                            data={item}
                            columns={columns}/>
                    </Fragment>
                })

            }
        }

    }

    const handlePageChange = (page: number) => {
        dispatch(updatePaginate(props.filterName, {page}))
        setIds([])
    }
    const handlePageSizeChange = (e: any) => {
        dispatch(updatePaginate(props.filterName, {
            page_size: parseInt(e.target.value),
            page: 1
        }))
        setIds([])
    }

    const handleChangeColumnState = (e: any) => {
        const _cols = columns.map((item: any, k: number) => {
            if(k == parseInt(e.target.value)) item.state = !item.state
            return item
        })
        dispatch(updateDataSetColumns({[props.columnKey]: _cols}))
    }

    const handleCheckAll = (e: any) => {
        const idKey = props.idKey || 'id'
        //console.log(115, idKey)
        if(resultRedux?.data?.length > 0) {
            let _ids: any[]
            if(resultRedux?.data?.length == ids.length){
                _ids = []
            }else{
                _ids = _.map(resultRedux.data, (item: any) => item[idKey])
            }
            setIds(_ids)
        }
    }

    const handleCheckRow = (e: any) => {
        let _ids: any[]
        if(ids.includes(parseInt(e.target.value))){
            _ids = _.filter(ids, (i: any) => i != e.target.value)
        }else{
            _ids = [...ids, parseInt(e.target.value)]
        }
        setIds(_ids)
    }

    const popoverClickRootClose = (
        <Popover id="table-columns-setting" className="popover-container" title="Column display">
          <h4>Show/hide columns</h4>
          <ul>
          {columns.length > 0 && columns.map((c: any, k:number)=> {
            return <li key={k}><label><input type="checkbox" name={c.key} value={k} checked={c.state} onChange={handleChangeColumnState}/> {c.label}</label></li>
          })}
          </ul>
        </Popover>
    );

    const handleBulkAction = (e: any) => {
        switch (e.target.value){
            case "delete":
                handleConfirmDelete(ids)
                break;
        }
        e.target.value = ''
    }

    const handleConfirmDelete = (ids: any) => {
        setIds(ids)
        setDeleteDialog(true)
    }

    const handleDelete = () => {
        if(props.onDelete) {
            props.onDelete(ids)
            setDeleteDialog(false)
        }
    }

    useEffect(()=>{
        setProps(props)
    }, [props])

    useEffect(() => {
        if(props.pagination)
            dispatch(setPaginate(props.filterName, props.pagination))
        else
            dispatch(setPaginate(props.filterName))
        dispatch(setDataSetColumns(props.columnKey, props.columnTemplate))

    }, []);

    //console.log(187, resultRedux)

    return <div className="mt-4">

    <div className="card">
        <div className="card-body">
            <div className="table-responsive">
                <table className={"table table-dataset "+((resultRedux.loading || resultRedux?.data?.length == 0) ? '': 'table-hover')}>
                    <thead>
                        <tr>
                            <td colSpan={(activeColumns.length + 2)}>
                                <div className="table-config">
                                    <div className="left">
                                        {props.pagination.page_size > 0 && resultRedux?.current_page <= resultRedux?.last_page ? <>
                                        Display
                                        <select defaultValue={paginateRedux.page_size} value={paginateRedux.page_size} onChange={handlePageSizeChange}>
                                            <option value={1}>1</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={200}>200</option>
                                        </select>
                                        per page - in </> :
                                        <>Total </>}
                                        <strong>{resultRedux?.total || 0}</strong> record(s).
                                    </div>
                                    <div className="right">
                                    <OverlayTrigger
                                        trigger="click"
                                        rootClose
                                        placement="bottom"
                                        overlay={popoverClickRootClose}
                                    >
                                        <span>Columns display</span>
                                    </OverlayTrigger>

                                            {props.bulkActionComponent && <>
                                            |
                                            <span>
                                                <BulkActionComponent ids={ids} onBulkAction={(data:any)=> {
                                                    if(props.bulkAction) props.bulkAction(data)
                                                }}/>
                                                {/*<select onChange={handleBulkAction}>*/}
                                                {/*    <option value={''}>Bulk action</option>*/}
                                                {/*    <option value={'delete'}>Xóa hàng loạt</option>*/}
                                                {/*</select>*/}
                                            </span>
                                            </>}
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </thead>
                    <thead className={'table-header'}>
                        <tr>
                            {props.idKey !== 'hidden' && <><th><label><input checked={resultRedux?.data?.length == ids.length} type="checkbox" name="checkall" onChange={handleCheckAll}/> Select all</label></th></>}
                            {renderTableHeader()}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableBody()}
                    </tbody>

                </table>
            </div>
        {(props.pagination.page_size > 0 && resultRedux?.current_page <= resultRedux?.last_page) &&
            (((resultRedux?.current_page == 1 && resultRedux.data.length) || +resultRedux?.current_page > 1) ? <Pagination className={"pagination justify-content-end"} total={resultRedux?.last_page} current={(paginateRedux.page-1)} onPageChange={handlePageChange}/> : null)
        }
        </div>
    </div>
        <KDialog show={deleteDialog}
                 variant={'danger'} icon={<i className="fa-regular fa-trash-can"></i>}
                 title={"Notice"}
                 onSubmit={handleDelete}
                 submitBtn={"Let's do it"}
                 onCancel={()=>{
                     setDeleteDialog(false)
                 }}
                 cancelBtn={"Cancel"}
                 overlay={true}
        >
            <p>Please ensure that you won't be able to recover the data after deletion.</p>

        </KDialog>
    </div>
}

export default DataSet;
