import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import {
//   requestFetchConversation,
//   requestFetchConversationDetail,
// } from "../store/actions"
import { updatePaginate } from "core/store/actions";
import { parseFilterSelect } from "core/utils/helper";
import { change } from "redux-form";
import { requestFetchMerchantStatistic, requestFetchPostStatistic, requestFetchStatistic, requestFetchTotalStatistic, requestFetchUserStatistic } from "../store/actions";
import moment from "moment";

export const formNameStatistics = "statisticsMgmt";
export const formNameTotalStatistics = "totalStatisticsMgmt";
export const formNameUseStatistic = "userStatisticMgmt";
export const formNameMerchantStatistic = "merchantStatisticMgmt";
export const formNamePostStatistic = "postStatisticMgmt";

export const useStatisticParam = () => {
  const filterRedux = useSelector(
    (state: any) => state.form[formNameStatistics]?.values
  );
  const paginateUserRedux = useSelector(
    (state: any) => state.paginate[formNameUseStatistic]
  );
  const reduxDispatch = useDispatch();
  const [param, setParam] = useState({
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (paginateUserRedux) {
      setParam((p: any) => ({
        ...(p || {}),
        userStatisticMgmt_page: paginateUserRedux?.page || 1,
        userStatisticMgmt_page_size: paginateUserRedux?.page_size || 20,
      }));
    }
  }, [paginateUserRedux]);

  useEffect(() => {
    if (filterRedux) {
      setParam((p: any) => ({
        ...(p || {}),
        startDate:
          filterRedux?.date?.startDate ||
          moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: filterRedux?.date?.endDate || moment().format("YYYY-MM-DD"),
      }));
    }
  }, [filterRedux]);

  // clear query_value when query_field change
  // useEffect(() => {
  //   reduxDispatch(change(formNameStatistics, "query_value", ""));
  // }, [filterRedux?.date?.startDate, filterRedux?.date?.endDate]);

  const handlePageChange = (page: number, formName: string) => {
    let _formNamePage: any = `${formName}_page`;
    reduxDispatch(updatePaginate(formName, { [_formNamePage] : page}));
  };

  return {
    param,
    handlePageChange,
  };
};


export const useStatistic = (param: any) => {
  const reduxDispatch = useDispatch();
  const redux = useSelector((state: any) => state.Dashboard);

  const handleFetchStatistic = () => {
    reduxDispatch(requestFetchStatistic({}));
  };
  
  return [redux[formNameStatistics], handleFetchStatistic];
};


export const useTotalStatistic = (cb: any) => {
  const reduxDispatch = useDispatch();
  const redux = useSelector((state: any) => state.Dashboard);

  const fetchTotalStatistic = (param: any) => {
    reduxDispatch(requestFetchTotalStatistic({
      start_date: param.startDate,
      end_date: param.endDate,
    }));
  };

  return [redux[formNameTotalStatistics], fetchTotalStatistic];
};

export const useUserStatistic = (param: any) => {
  const reduxDispatch = useDispatch();
  const redux = useSelector((state: any) => state.Dashboard);

  const handleFetchStatistic = () => {
    reduxDispatch(requestFetchUserStatistic(param));
  };

  return [redux.formNameUseStatistic, handleFetchStatistic];
};

export const useMerchantStatistic = (param: any) => {
  const reduxDispatch = useDispatch();
  const redux = useSelector((state: any) => state.Dashboard);

  const handleFetchStatistic = () => {
    reduxDispatch(requestFetchMerchantStatistic(param));
  };

  return [redux.formNameMerchantStatistic, handleFetchStatistic];
};

export const usePostStatistic = (param: any) => {
  const reduxDispatch = useDispatch();
  const redux = useSelector((state: any) => state.Dashboard);

  const handleFetchStatistic = () => {
    reduxDispatch(requestFetchPostStatistic(param));
  };

  return [redux.formNamePostStatistic, handleFetchStatistic];
};


// export const useStatisticDetail = () => {
//   const { id } = useParams()
//   const reduxDispatch = useDispatch()
//   const redux = useSelector((state: any) => state.Chat)

//   useEffect(() => {
//     if (id) {
//       reduxDispatch(requestFetchConversationDetail(id))
//     }
//   }, [id])

//   return redux.conversation
// }
