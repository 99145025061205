import { config } from "core/config/app";
import { removeAxiosToken, setAxiosToken } from "core/networks/API";
import { deviceStorage } from "core/utils/devicestorage";

export interface DataState {
    conversations: any;
    messages: any;
    conversation: any;
}

const initialState: DataState = {
    conversations: {
        data: [],
        loading: false,
        error: null,
        type: ""
    },
    messages: {
        data: [],
        loading: false,
        error: null,
        type: ""
    },
    conversation: {
        data: [],
        loading: false,
        error: null,
        type: ""
    },
};

const AuthReducer = (
    state: DataState = initialState,
    action: any,
): DataState => {
    switch (action.type) {
        case "REQUEST_FETCH_CONVERSATION":
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    type: action.type,
                    loading: true,
                }
            };
        case "REQUEST_FETCH_CONVERSATION_SUCCESS":
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    ...action.payload.data,
                    type: action.type,
                    loading: false,
                },
            };
        case "REQUEST_FETCH_CONVERSATION_FAIL":
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    type: action.type,
                    loading: false,
                }
            };

        case "REQUEST_FETCH_MESSAGE":
            return {
                ...state,
                messages: {
                    ...state.messages,
                    type: action.type,
                    loading: true,
                    payload: action.payload
                }
            };
        case "REQUEST_FETCH_MESSAGE_SUCCESS":
            let messages = {
                ...state.messages,
                type: action.type,
                loading: false,
            }
            if (!state.messages?.payload?.last_id) {
                messages = {
                    ...messages,
                    ...action.payload.data,
                }
            } else {
                messages.data = [...action.payload.data.data, ...messages.data]
                messages = {
                    ...action.payload.data,
                    ...messages,
                }
            }
            messages.data = messages.data.sort((a: any, b: any) => a.id - b.id)
            return {
                ...state,
                messages,
            };
        case "REQUEST_FETCH_MESSAGE_FAIL":
            return {
                ...state,
                messages: {
                    ...state.messages,
                    type: action.type,
                    error: action.payload,
                    loading: false,
                }
            };

        case "REQUEST_FETCH_CONVERSATION_DETAIL":
            return {
                ...state,
                conversation: {
                    ...state.conversation,
                    type: action.type,
                    loading: true,
                }
            };
        case "REQUEST_FETCH_CONVERSATION_DETAIL_SUCCESS":
            return {
                ...state,
                conversation: {
                    ...state.conversation,
                    ...action.payload.data,
                    type: action.type,
                    loading: false,
                },
            };
        case "REQUEST_FETCH_CONVERSATION_DETAIL_FAIL":
            return {
                ...state,
                conversation: {
                    ...state.conversation,
                    type: action.type,
                    error: action.payload,
                    loading: false,
                }
            };
        default:
            return state;
    }
};

export default AuthReducer
