export default {}

export const requestLogin = (credentials: any) => ({
    type: "REQUEST_LOGIN",
    payload: credentials
})

export const requestLoginSuccess = (payload: any) => ({
    type: "REQUEST_LOGIN_SUCCESS",
    payload
})

export const requestLoginFail = (payload: any) => ({
    type: "REQUEST_LOGIN_FAIL",
    payload
})

export const requestFetchAuth = () => ({
    type: "REQUEST_FETCH_AUTH"
})

export const requestFetchAuthSuccess = (payload: any) => ({
    type: "REQUEST_FETCH_AUTH_SUCCESS",
    payload
})

export const requestFetchAuthFail = (payload: any) => ({
    type: "REQUEST_FETCH_AUTH_FAIL",
    payload
})

export const requestLogout = () => ({
    type: "REQUEST_LOGOUT"
})