import { useParams, useLocation } from "react-router-dom"
import InputComponent from "core/components/InputComponent";
import SelectComponent from "core/components/SelectComponent";
import { RouterName } from "core/router";
import { useCustomNavigate } from "core/utils/helper";
import { updatePaginate } from "core/store/actions";
import {FormEvent, useEffect} from "react";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import DateRangePicker from "core/components/DateRangePicker";
import DateRangePicker2 from "core/components/DateRangePicker2";
import { change } from "redux-form";

let formName = 'messageMgmt'
interface CustomProps {
  filterName: string,
}
const MessageFilter: React.FC<CustomProps & InjectedFormProps<{}, CustomProps>> = (props: any) => {
  const navigate = useCustomNavigate()
  const { id } = useParams()
  const location = useLocation()
  const locationSearch = new URLSearchParams(useLocation().search);
  // //console.log(props)
  const { error, handleSubmit, pristine, reset, submitting, dispatch } = props
  const search_id = locationSearch.get('search_id')
  const search_text = locationSearch.get('search_text')

  const handleFilterSubmit = (e: any) => {
    e.preventDefault()
  }

  const backToSearch = () => {
    dispatch(change(formName, 'keyword', search_text))
    dispatch(updatePaginate(formName, {
      last_id: null,
      limit: 10,
      scroll_type: 'down',
      id: '',
    }))
    navigate(location.pathname)
  }

  return <form onSubmit={handleFilterSubmit} className="form-filter row">
    <div className="col-12 m-0">
      <div className="row">
        <div
          className={`col-1 ${(search_id && search_text) ? 'd-flex cursor-pointer' : 'd-none'} justify-content-center align-items-center pt-3 mt-2`}
          onClick={backToSearch}
        >
          <i className="fa-solid fa-chevron-left"></i>
        </div>
        <div className={`col-1${(search_id && search_text) ? 1 : 2}`}>
          <Field
            name="keyword"
            type="text"
            disabled={!id || submitting}
            component={InputComponent}
            label={''}
            handleDispatch={dispatch}
            formName={formName}
            placeholder="Search in conversation"
          />
        </div>
      </div>
    </div>
  </form>
}

export default reduxForm<{}, CustomProps>({
  form: formName
})(MessageFilter);
