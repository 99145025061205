import {useEffect} from "react";
import moment from "moment";
import {formatDateTime, highlightKeywords, useCustomNavigate} from "../../../core/utils/helper";
import {RouterName} from "../../../core/router";
import {useSelector} from "react-redux";

const UserStatisticListRowTemplate = (props: any) => {
    const navigate = useCustomNavigate()
    const filterRedux = useSelector((state: any) => state.form[props.filterName]?.values)
    //console.log(filterRedux)
    useEffect(() => {
    }, []);

    useEffect(() => {
    }, [props]);

    const renderCell = (key: any) => {
        switch (key) {
            case 'id':
                return <>#423</>
            case 'phone':
                return <>0101994194</>
            case 'display_name':
                return <>Thích Thị Phan</>
            case 'signup_at':
                return <>2024-05-01 00:00:00</>
            default:
                return <></>
        }
    }

    const handlePreview = () => {
        window.open(`/${props.data?.slug?.alias}.html`, '_blank')
    }
    const handleEdit = () => {
    }
    const handleDelete = () => {
        if(props.onDelete) {
            props.onDelete([props.data.id])
        }
    }

    return <tr onDoubleClick={handleEdit}>
        {
            props.columns.length > 0 && props.columns.map((col: any, k: number) => {
                if(col.state) return <td key={k}>{renderCell(col.key)}</td>
            })
        }
    </tr>
}
export default UserStatisticListRowTemplate