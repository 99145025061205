import {Fragment, useEffect} from "react";
import moment from "moment";
import {useCustomNavigate} from "../../../../core/utils/helper";
import {RouterName} from "../../../../core/router";

const CategoryListRowTemplate = (props: any) => {
    const navigate = useCustomNavigate()

    useEffect(() => {
    }, []);

    useEffect(() => {
    }, [props]);

    const renderCell = (key: any) => {
        switch (key) {
            case 'name':
                return <td className={`depth-${(props.data.sort.split('|')).length - 2}`}>
                    <h5>{props.data[key]}</h5>
                    <em className="slug">/{props.data.slug.alias}</em>
                </td>
            case 'created_at':
            case 'updated_at':
                return <td>{moment(props.data[key]).format('DD/MM/YYYY HH:mm')}</td>
            default: return <td>{props.data[key]}</td>
        }
    }

    const handlePreview = () => {
        window.open(`/${props.data?.slug?.alias}.html`, '_blank')
    }
    const handleEdit = () => {
        if(props.onEdit) {
            props.onEdit(props.data)
        }
    }
    const handleDelete = () => {
        if(props.onDelete) {
            props.onDelete([props.data.id])
        }
    }

    const handleAddChild = () => {
        if(props.onAdd) {
            props.onAdd(props.data)
        }
    }

    return <tr>
        <td width={150}>
            <label>
                <input type={"checkbox"} value={props.data.id} checked={props.checked} onChange={props.onCheck}/> #{props.data.id}
            </label>
        </td>
        {
            props.columns.length > 0 && props.columns.map((col: any, k: number) => {
                if(col.state) return <Fragment key={k}>{renderCell(col.key)}</Fragment>
            })
        }
        <td className="text-end">
            <div className="actions">
                <a onClick={handleAddChild}><i className="fa-solid fa-plus"></i> <span>Thêm mục con</span></a>
                <a onClick={handleEdit}><i className="fa-solid fa-pen-to-square"></i> <span>Chỉnh sửa</span></a>
                <a onClick={handleDelete}><i className="fa-solid fa-trash-can"></i> <span>Xoá</span></a>
            </div>
        </td>
    </tr>
}
export default CategoryListRowTemplate