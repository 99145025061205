import DashboardLayout from "core/layouts/DashboardLayout"
import DataSet from "core/components/DataSet"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {fetchPawnDataList, initPawnDataList} from "../../store/actions";
import {formatDateTime, parseFilterSelect, useBackNavigate, useCustomNavigate} from "core/utils/helper";
import FormCreateCampaign from "./BulkPNCreateCampaignForm";
import {
    API_ADMIN_MERCHANT,
    API_COUPON_HISTORIES,
    API_COUPON_HISTORIES_STATISTIC,
    API_ISSUE_COUPON,
    API_COUPON_LIST,
} from "../../api";
import {toast} from "react-toastify";
import API from "../../../../core/networks/API";
import "modules/Pawn/assets/scss/coupon.scss"
import moment from "moment/moment";
import { Field } from "redux-form";
import KeywordComponent from "core/components/KeywordComponent";
import SelectComponent from "core/components/SelectComponent";
import { RouterName } from "core/router";
import "modules/Pawn/assets/scss/bulkpn.scss"

let reduxFuncName = 'bulkPushMgmt'
const BulkPNCreateCampaignPage = (props: any) => {
    const navigate = useCustomNavigate()
    const dispatch = useDispatch()
    const filterRedux = useSelector((state: any) => state.form[reduxFuncName]?.values)
    const paginateRedux = useSelector((state: any) => state.paginate[reduxFuncName])
    const navigateBack = useBackNavigate()
    const parseFilter = () => {
        let filter = {...filterRedux}
        filter.status = parseFilterSelect(filter.status)
        filter.issuedFrom = filter?.date?.startDate
        filter.issuedTo = filter?.date?.endDate
        filter.relations = 'coupon,user,issuedBy,settlementBy,merchant,property'
        //console.log({...filter, ...paginateRedux})
        return {...filter, ...paginateRedux}
    }
    const [issueData, setIssueData] = useState({
        phone: "",
        couponId: ""
    })
    const [issueError, setIssueError] = useState({
        phone: "",
        couponId: ""
    })

    const [couponDetailDialog, setCouponDetailDialog] = useState(false)
    const [couponDetail, setCouponDetail] = useState<any>(null)

    
    
    const [coupons, setCoupon] = useState<any>([])

    const callFetchData = () => {
        dispatch(fetchPawnDataList(reduxFuncName, 'GET', API_COUPON_HISTORIES, parseFilter()))
    }

    

    const callFetchCouponList = async() => {
        try{
            const res = await API.get(API_COUPON_LIST, {
                state: 'ENABLED',
                page_size: 0,
            })
            //console.log(79, res)
            if(res.data?.data) {
                setCoupon(res.data.data)
                setIssueData((p: any) => ({...p, couponId: res.data.data[0]?.id || ''}))
            }
        } catch (e) {
            console.error(e)
        }
    }

    const callDeleteData = async (ids: any) => {

    }

    const handleCancle =(data: any) => {
        // navigate(RouterName.MKT_BULK_PN_LIST)
        // setConfirmDialog(true)
    }

    const handleBulkDelete = (ids: any) => {
        //console.log(32, ids)
        callDeleteData(ids)
    }

    const handleCreateBulkPush = () => {
        //console.log("HANDLE ISSUE COUPON")
        // setIssueDialog(true)

    }

    const handleSubmitIssueForm = async () => {
        try{
            //console.log(issueData)
            const res = await API.post(API_ISSUE_COUPON, issueData)
            toast.success("Successful coupon issuance")
            // setIssueDialog(false)
            callFetchData()
        } catch (e: any) {
            //console.log(e.response)
            // setIssueError({phone: "Issuing coupons failed, undefined error", couponId: ""})
            if([400, 422].includes(e.response.status)){
                setIssueError({phone: e.response.data.msg, couponId: ""})
            } else{
                toast.error("Issuing coupons failed, undefined error")
                // setIssueDialog(false)
            }
        }

    }

    const handleChangeIssuePhone = (e: any) => {
        setIssueData((p: any) => ({...p, phone: e.target.value}))
    }

    const handleChangeIssueCoupon = (e: any) => {
        setIssueData((p: any) => ({...p, couponId: e.target.value}))
    }

    const handleDetail = (item: any) => {
        //console.log("DETAIL", item)
        setCouponDetail(item)
        setCouponDetailDialog(true)
    }


    useEffect(() => {
        if(paginateRedux) {
            callFetchData()
        }
    }, [paginateRedux]);

    useEffect(() => {
        // dispatch(initPawnDataList(reduxFuncName))
        callFetchCouponList()
    }, []);

    const handleGoBack = () => {
        // navigate(RouterName.MKT_BULK_PN_LIST)
        navigateBack()
    }

    return <>
        <DashboardLayout currentPage="dashboard/marketing/bulk-pn" title="Coupon Issue history" id="create-campaign">
        <h1><span className="go-back" onClick={handleGoBack}><i
                className="fa-solid fa-chevron-left"></i> Back</span> Create Campaign - Bulk Push Notification</h1>
            
            <div>

                <FormCreateCampaign
                    filterName={reduxFuncName}
                    onSubmit={handleCancle}
                    onCreate={handleCreateBulkPush}
                    initialValues={{
                        status: "",
                        keyword: "",
                        action:"NONE",
                        filter_region:"1"
                    }}
                />
            </div>
            
        </DashboardLayout>
    </>
}

export default BulkPNCreateCampaignPage
