import { config } from "core/config/app";
import Dashboard from "./pages/Dashboard";

const router =  [
    {
        name: "DASHBOARD_PAGE",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard/overview`,
        element: <Dashboard />
    },
    {
        name: "DASHBOARD",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard`,
        element: <Dashboard />
    },
]
export default router
