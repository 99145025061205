import Loading from 'core/components/Loading'

const ChatLoading = (props: any) => {
  return <div className="w-100 h-100 position-absolute loading">
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <Loading type="type3" />
    </div>
  </div>
}

export default ChatLoading
