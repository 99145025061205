import API from "core/networks/API";
import { call, put, takeLatest } from "redux-saga/effects";
import { requestFetchAuthFail, requestFetchAuthSuccess, requestLoginFail, requestLoginSuccess } from "./actions";
import {API_ADMIN_FETCH_ME, API_ADMIN_AUTH_LOGIN} from "../api";

function* loginAuth(_action: any): any{
  try {
    const response = yield call(() => {
      return API.post(API_ADMIN_AUTH_LOGIN, _action.payload);
    });
    const { data } = response;
    
    if (data?.code === 200) {
      yield put(requestLoginSuccess(data));
    }else{
      yield put(requestLoginFail(data));
    }
  } catch (error) {
    yield put(requestLoginFail(error));
  }
}

function* fetchAuth(_action: any): any{
  try {
    const response = yield call(() => {
      return API.get(API_ADMIN_FETCH_ME, _action.payload);
    });
    const { data } = response;
    
    if (data?.code === 200) {
      yield put(requestFetchAuthSuccess(data));
    }else{
      yield put(requestFetchAuthFail(data));
    }
  } catch (error) {
    yield put(requestFetchAuthFail(error));
  }
}
  

export default function* AuthSaga() {
  yield takeLatest("REQUEST_LOGIN", loginAuth)
  yield takeLatest("REQUEST_FETCH_AUTH", fetchAuth)
}