import {useDispatch, useSelector} from "react-redux";
import DashboardLayout from "core/layouts/DashboardLayout";
import CouponReconciliationFilter from "./CouponReconciliationFilter";
import DataSet from "../../../../core/components/DataSet";
import "modules/Pawn/assets/scss/coupon.scss"
import CouponStatisticTemplate from "./CouponStatisticTemplate";
import CouponReconciliationStatisticTemplate from "./CouponReconciliationStatisticTemplate";
import {formatDateTime, numberFormat, parseFilterSelect, setParamsUrl} from "../../../../core/utils/helper";
import {fetchPawnDataList} from "../../store/actions";
import {
    API_ADMIN_MERCHANT,
    API_ADMIN_MERCHANT_STATISTIC,
    API_COUPON_RECONCILIATION, API_COUPON_RECONCILIATION_SETTLEMENT,
    API_COUPON_RECONCILIATION_STATISTIC
} from "../../api";
import {useEffect, useState} from "react";
import CouponHistoryListRowTemplate from "./CouponHistoryListRowTemplate";
import CouponReconciliationListRowTemplate from "./CouponReconciliationListRowTemplate";
import CouponReconciliationBulkAction from "./CouponReconciliationBulkAction";
import KDialog from "../../../../core/components/Dialog/KDialog";
import API from "../../../../core/networks/API";
import {toast} from "react-toastify";
import {useQuery} from "../../../../core/hooks/useQuery";
let reduxFuncName = 'couponReconciliationMgmt'
const CouponReconciliationPage = (props: any) => {
    const dispatch = useDispatch()
    const filterRedux = useSelector((state: any) => state.form[reduxFuncName]?.values)
    const paginateRedux = useSelector((state: any) => state.paginate[reduxFuncName])

    const [settlementPanel, setSettlementPanel] = useState(false)

    const queryParams = useQuery()
    const [queryParamsState, setQueryParamsState] = useState<any>({
        recon_status: queryParams.get("recon_status") || "",
        keyword: queryParams.get("keyword") || "",
        merchant: queryParams.get("merchant") || "",
        coupon: queryParams.get("coupon") || "",
        usedFrom: queryParams.get("usedFrom") || "",
        usedTo: queryParams.get("dateTo") || "",
        relations: 'createdBy',
        page: queryParams.get("page") || "",
        page_size: queryParams.get("page_size") || ""
    })

    const parseFilter = () => {
        console.log(35, queryParamsState)
        return queryParamsState;
    }

    const setFilter = () => {
        const filter: any = {
            recon_status: parseFilterSelect(filterRedux?.recon_status),
            keyword: filterRedux?.keyword,
            merchant: filterRedux?.merchant,
            coupon: filterRedux?.coupon,
            usedFrom: filterRedux?.date?.startDate,
            usedTo: filterRedux?.date?.endDate,
            relations: 'coupon,user,issuedBy,settlementBy,merchant,property',
            page: paginateRedux?.page,
            page_size: paginateRedux?.page_size
        }
        setQueryParamsState(filter)
        setParamsUrl(filter)
        return filter;
    }

    const getDefaultFilterValue = () => {
        return {
            recon_status: queryParams.get("recon_status") || "",
            keyword: queryParams.get("keyword"),
            merchant: queryParams.get("merchant"),
            coupon: queryParams.get("coupon"),
            date: {
                startDate: queryParams.get("usedFrom"),
                endDate: queryParams.get("usedTo")
            }
        }
    }

    const callFetchData = () => {
        dispatch(fetchPawnDataList(reduxFuncName, 'GET', API_COUPON_RECONCILIATION, parseFilter()))
    }

    const defaultStatistic = {
        "total": 0,
        "total_amount": 0,
        "settled_amount": 0,
        "available_amout": 0
    }
    const [statisticData, setStatisticData] = useState({...defaultStatistic})

    const callFetchStatisticData = async() => {
        try{
            setStatisticData(defaultStatistic)
            const res = await API.get(API_COUPON_RECONCILIATION_STATISTIC, parseFilter())
            if(res.data?.data) setStatisticData(res.data.data)
        } catch (e) {
            console.error(e)
        }
    }

    const [settlementData, setSettlementData] = useState({
        list: [],
        total: 0,
        settlementDate: ''
    })

    const [settlementSelectedIds, setSettlementSelectedIds] = useState<any>([])
    const callSettlementReconciliation = async(verify: boolean, ids: any, settlementDate: any = null) => {
        try{
            // setStatisticData(defaultStatistic)
            const res = await API.post(API_COUPON_RECONCILIATION_SETTLEMENT, {verify, ids, settlementDate})
            if(res.data?.data){
                if(verify) {
                    setSettlementData(res.data.data);
                    setSettlementPanel(true)
                }
                else {
                    setSettlementData({
                        list: [],
                        total: 0,
                        settlementDate: ''
                    })
                    setSettlementSelectedIds([])
                    toast.success("The settlement has been successfully completed!")
                    clearCheckBox()
                    callFetchData()
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    const clearCheckBox = () => {
        let checkboxs = document.querySelectorAll('.idCheck.checked')
        if (checkboxs.length) {
            Array.from(checkboxs).map((checkbox: any) => {
                checkbox.click()
            })
        }
    }


    const handleChangeFilter =(data: any) => {
        setFilter()
    }

    const handleBulkActionSuccess = (actionData: any) =>{
        //console.log(39, 'handleBulkActionSuccess', actionData)
        if(actionData.action === "SETTLEMENT") {
            if(actionData.ids.length > 0){
                // @ts-ignore
                setSettlementSelectedIds(actionData.ids)
                callSettlementReconciliation(true, actionData.ids)
            } else toast.error("Please select the coupon transaction to settlement.")

        }
    }

    const handleSubmitSettlement = () => {
        callSettlementReconciliation(false, settlementSelectedIds, settlementData?.settlementDate)
        closeSettlementPanel()
    }

    const closeSettlementPanel = () => {
        setSettlementSelectedIds([])
        setSettlementPanel(false)
    }

    useEffect(() => {
        if(paginateRedux) {
            if(paginateRedux.page != queryParamsState.page || paginateRedux.page_size != queryParamsState.page_size){
                setFilter()
            }
        }
    }, [paginateRedux]);

    useEffect(() => {
        callFetchData()
        callFetchStatisticData()
    }, []);

    useEffect(() => {
        callFetchData()
        callFetchStatisticData()
    }, [queryParamsState]);

    return <>
        <DashboardLayout currentPage="dashboard/coupon/reconciliation" title="Coupon Reconcilication history">
            <h1>COUPON RECONCILIATION</h1>
            <CouponReconciliationFilter
                filterName={reduxFuncName}
                onSubmit={handleChangeFilter}
                onCreate={()=>{}}
                initialValues={getDefaultFilterValue()}/>

            <div className="mt-5">
                <CouponReconciliationStatisticTemplate data={statisticData}/>
            </div>

            <DataSet
                idKey={'id'}
                filterName={reduxFuncName}
                module={'Pawn'}
                columnKey={reduxFuncName}
                pagination={{page: queryParams.get("page") || 1, page_size: queryParams.get("page_size") || 20}}
                columnTemplate={[
                    {label: "Coupon Code", key: "coupon_code", state: true, sort: false},
                    {label: "Merchant", key: "merchant", state: true, sort: false},
                    {label: "User", key: "user", state: true, sort: false},
                    {label: "Property", key: "property", state: true, sort: false},
                    {label: "Amount", key: "amount", state: true, sort: false},
                    {label: "Used at", key: "approved_at", state: true, sort: false},
                    {label: "Settlement at", key: "settlement_at", state: true, sort: false},
                    {label: "Settlement by", key: "settlement_by", state: true, sort: false},
                    {label: "State", key: "state", state: true, sort: false},
                ]}
                onEdit={()=>{}}
                rowTemplate={CouponReconciliationListRowTemplate}
                bulkActionComponent={CouponReconciliationBulkAction}
                bulkAction={handleBulkActionSuccess}
            />

            <KDialog
                variant={'success text-start settlement-panel'}
                icon={<i className="fa-solid fa-file-invoice-dollar"></i>}
                title={"Settlement coupon"}
                show={settlementPanel}
                overlay={true}
                onCancel={closeSettlementPanel}
                cancelBtn={"Cancel"}
                onSubmit={()=>handleSubmitSettlement()}
                submitBtn={"Confirm"}
            >
                <p>Confirmation of settlement with the following details:</p>

                <table className={'table table-bordered'}>
                    <tbody>
                    <tr>
                        <th>Settlement date:</th>
                        <td>{settlementData?.settlementDate && formatDateTime(settlementData?.settlementDate)}</td>
                    </tr>
                    <tr>
                        <th>Total amount:</th>
                        <td>{settlementData?.total && numberFormat(settlementData?.total, '.')} VND</td>
                    </tr>
                    <tr className={'division'}><td></td></tr>
                    <tr>
                        <th>Merchant</th>
                        <th>Amount</th>
                    </tr>
                    {
                        settlementData?.list?.length > 0 && settlementData.list.map((item: any, k: number)=> (
                            <tr key={k}>
                                <td>{item.name}</td>
                                <td>{numberFormat(item.amount, '.')} VND</td>
                            </tr>
                        ))
                    }

                    </tbody>
                </table>
                <p><em className={'text-danger'}>Note: Settlement action will be recorded and cannot be changed, please
                    double-check before proceeding.</em></p>
            </KDialog>
        </DashboardLayout>
    </>
}

export default CouponReconciliationPage
