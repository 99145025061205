import DashboardLayout from "core/layouts/DashboardLayout"
import DataSet from "core/components/DataSet"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {fetchPawnDataList} from "../../store/actions";
import MerchantListRowTemplate from "./MerchantListRowTemplate";
import API from "core/networks/API";
import MerchantFilter from "./MerchantFilter";
import MerchantStatisticTemplate from "./MerchantStatisticTemplate";
import {API_ADMIN_MERCHANT, API_ADMIN_MERCHANT_STATISTIC} from "../../api";
import {useQuery} from "core/hooks/useQuery";
import {parseFilterSelect, setParamsUrl} from "../../../../core/utils/helper";
import moment from "moment";

let reduxFuncName = 'merchantMgmt'
const MerchantListPage = (props: any) => {
    const dispatch = useDispatch()
    const filterRedux = useSelector((state: any) => state.form[reduxFuncName]?.values)
    const paginateRedux = useSelector((state: any) => state.paginate[reduxFuncName])

    const queryParams = useQuery()
    const [queryParamsState, setQueryParamsState] = useState<any>({
        status: queryParams.get("status") || "",
        keyword: queryParams.get("keyword") || "",
        startDate: queryParams.get("startDate") || "",
        endDate: queryParams.get("endDate") || "",
        page: queryParams.get("page") || "",
        page_size: queryParams.get("page_size") || ""
    })

    const parseFilter = () => {
        return queryParamsState;
    }

    const setFilter = () => {
        const filter: any = {
            status: parseFilterSelect(filterRedux?.status),
            keyword: parseFilterSelect(filterRedux?.keyword),
            startDate: filterRedux?.date?.startDate,
            endDate: filterRedux?.date?.endDate,
            page: paginateRedux?.page,
            page_size: paginateRedux?.page_size
        }
        setQueryParamsState(filter)
        setParamsUrl(filter)
        return filter;
    }

    const getDefaultFilterValue = () => {
        return {
            status: queryParams.get("status") || "",
            keyword: queryParams.get("keyword"),
            date: {
                startDate: queryParams.get("startDate") || moment().format("YYYY-MM-DD"),
                endDate: queryParams.get("endDate") || moment().format("YYYY-MM-DD")
            }
        }
    }

    const callFetchData = () => {
        dispatch(fetchPawnDataList(reduxFuncName, 'GET', API_ADMIN_MERCHANT, parseFilter()))
    }

    const defaultStatistic = {
        "total": 0,
        "approved": 0
    }
    const [statisticData, setStatisticData] = useState({...defaultStatistic})

    const callFetchStatisticData = async() => {
        try{
            setStatisticData(defaultStatistic)
            const res = await API.get(API_ADMIN_MERCHANT_STATISTIC, parseFilter())
            //console.log(53, res)
            if(res.data?.data) setStatisticData(res.data.data)
        } catch (e) {
            console.error(e)
        }
    }

    const callDeleteData = async (ids: any) => {
        // try{
        //     await API.delete("/cms/v1/content/page", {ids})
        //     callFetchData()
        // } catch(e: any){
        //     console.error(e)
        // }
    }

    const handleChangeFilter =(data: any) => {
        // callFetchData()
        // callFetchStatisticData()
        setFilter()
    }

    const handleBulkDelete = (ids: any) => {
        //console.log(32, ids)
        callDeleteData(ids)
    }

    useEffect(() => {
        if(paginateRedux) {
            if(paginateRedux.page != queryParamsState.page || paginateRedux.page_size != queryParamsState.page_size){
                setFilter()
            }
        }
    }, [paginateRedux]);

    useEffect(() => {
        // dispatch(initPawnDataList(reduxFuncName))
        callFetchData()
        callFetchStatisticData()
    }, []);

    useEffect(() => {
        callFetchData()
        callFetchStatisticData()
    }, [queryParamsState]);

    return <>
        <DashboardLayout currentPage="dashboard/merchants" title="Merchant management">
            <h1>MERCHANT MANAGEMENT</h1>
            <MerchantFilter
                        onSubmit={handleChangeFilter}
                        initialValues={getDefaultFilterValue()}/>
            <div className="mt-5">
                <MerchantStatisticTemplate data={statisticData}/>
            </div>
            <DataSet
                idKey={'shop_id'}
                filterName={reduxFuncName}
                module={'Pawn'}
                columnKey={reduxFuncName}
                pagination={{page: queryParams.get("page") || 1, page_size: queryParams.get("page_size") || 20}}
                columnTemplate={[
                    {label: "Merchant name", key: "mc_username", state: true, sort: false},
                    {label: "Merchant phone", key: "mc_phone", state: true, sort: false},
                    {label: "Shop name", key: "shop_name", state: true, sort: false},
                    // {label: "Shop licence", key: "biz_lcnc_path", state: true, sort: false},
                    {label: "Shop address", key: "shop_addr_1", state: true, sort: false},
                    {label: "Created at", key: "mc_created_at", state: true, sort: false},
                    {label: "State", key: "mc_state", state: true, sort: false},
                ]}
                rowTemplate={MerchantListRowTemplate}
            />
        </DashboardLayout>
    </>
}

export default MerchantListPage