import {useEffect} from "react";
import moment from "moment";
import {formatDateTime, highlightKeywords, useCustomNavigate} from "../../../../core/utils/helper";
import {RouterName} from "../../../../core/router";
import {useSelector} from "react-redux";

const FILTER_TYPE = [
    { value: '', label: 'All' },
    { value: 'EVENT', label: 'Event' },
    { value: 'UPDATE', label: 'Update Notify' },
    { value: 'OTHER', label: 'Other' },
];

const BulkPNCampaignRowTemplate = (props: any) => {
    const navigate = useCustomNavigate()
    const filterRedux = useSelector((state: any) => state.form['couponHistoryMgmt']?.values)
    // //console.log(filterRedux)
    useEffect(() => {
    }, []);

    useEffect(() => {
    }, [props]);

    const renderStateLabel = (label: string) => {
        switch (label) {
            case 'AVAILABLE':
                return <span className="badge rounded-pill bg-info">AVAILABLE</span>
            case 'USED':
                return <span className="badge rounded-pill bg-success">USED</span>
            case 'VALIDATING':
                return <span className="badge rounded-pill bg-warning">VALIDATING</span>
            case 'USING':
                return <span className="badge rounded-pill bg-warning">USING</span>
            case 'EXPIRED':
                return <span className="badge rounded-pill bg-secondary">EXPIRED</span>
            case 'ACTIVED':
                return <span className="badge rounded-pill bg-warning">ACTIVED</span>
            default:
                return <></>
        }
    }

    const renderCell = (key: any) => {
        switch (key) {
            case 'created_at':
                return <>{formatDateTime(props.data?.created_at)}</>
            case 'type':
                const typeStr = FILTER_TYPE.find((item: any) => (item.value === props.data?.type));
                return <>{typeStr?.label}</>
            case 'campaign_name':
                return <>{props.data?.name}</>
            case 'total_target':
                return <>{props.data?.total_recipients}</>
            case 'sent':
                return <>{props.data?.total_sent}</>
            case 'success':
                return <>{props.data?.total_success}</>
            case 'failure':
                return <>{props.data?.total_failure}</>
            case 'created_by':
                return <>{props.data?.created_by?.username}</>
            default:
                return <>null</>
        }
    }

    const handlePreview = () => {
        window.open(`/${props.data?.slug?.alias}.html`, '_blank')
    }
    const handleEdit = () => {
        props.onEdit(props.data)
    }
    const handleDelete = () => {
        if(props.onDelete) {
            props.onDelete([props.data.id])
        }
    }

    return <tr onDoubleClick={handleEdit}>
        {
            props.columns.length > 0 && props.columns.map((col: any, k: number) => {
                if(col.state) return <td key={k}>{renderCell(col.key)}</td>
            })
        }
        <td className="text-end">
            <div className="actions">
                {/*<a onClick={handlePreview}><i className="fa-solid fa-eye"></i> <span>Xem</span></a>*/}
                {/*<a onClick={handleEdit}><i className="fa-solid fa-pen-to-square"></i> <span>Edit</span></a>*/}
                {/*<a onClick={handleDelete}><i className="fa-solid fa-trash-can"></i> <span>Re</span></a>*/}
            </div>
        </td>
    </tr>
}
export default BulkPNCampaignRowTemplate
