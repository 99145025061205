import { config } from "core/config/app";
// import { useFetchAppSettingClient } from "modules/Client/AppSettings/hooks";
// import { logout as logoutClient } from "modules/Client/Auth/store/actions";
// import { useFetchAppSettingMerchant } from "modules/Pawn/AppSettings/hooks";
// import { logoutMerchantRequest } from "modules/Pawn/Auth/store/actions";
import 'react-toastify/dist/ReactToastify.css';
import {useEffect, useState} from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import {BrowserRouter, RouterProvider} from "react-router-dom";
import "./App.scss";
import useHandleError from "./core/hooks/useHandleError";
import {initApplication, settingApplication, setupAxios} from "./core/networks/API";
import { AppRouter } from "./core/router";
// import { useFetchMe as useFetchClient } from "./modules/Client/Auth/hooks";
// import useAccountSocket from "./modules/Client/Auth/hooks/useSocket";
// import { useFetchMe as useFetchMerchant } from "./modules/Pawn/Auth/hooks";
import Splash from "./Splash";
import { ToastContainer } from "react-toastify";
import {requestGlobalSetting, setDebugLog, setNativeReady} from "./core/store/actions";
import {deviceStorage} from "./core/utils/devicestorage";
import {_t} from "./core/language";
import {useCustomNavigate} from "./core/utils/helper";
import { requestFetchAuth } from "modules/Auth/store/actions";
// import { logout as logoutMerchant } from "modules/Pawn/Auth/store/actions";
const router = AppRouter;


setupAxios();
const App = () => {
  const auth = useSelector((state: any) => state.Auth);
  const common = useSelector((state: any) => state.Common);



  const [debugUrl, setDedugUrl] = useState("")

  const dispatch = useDispatch();
  useHandleError();

  useEffect(() => {
    initApplication();
    (window as any).webViewDocumentReady()
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      //console.log("PING...");
    }, 20000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  });


  useEffect(() => {
    settingApplication();
    setupAxios();

    dispatch(requestGlobalSetting())

    if(deviceStorage[config.appStorage].getItem(config.appPackage+"_X_TOKEN")){
      dispatch(requestFetchAuth())
    }
  }, []);


  const goDebugUrl = () => {
    window.location.href = debugUrl
  }


  const isFetchSetting = () => {
    if(common.type == 'REQUEST_GLOBAL_SETTING_SUCCESS') return true;
  }



  return (
    <>

      {
        (isFetchSetting())? <>
            <RouterProvider router={router}></RouterProvider>
            <BrowserRouter></BrowserRouter>
            <div id="overlay"></div>
          </> : <Splash/>
      }
      
      <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />

    </>
  );
};

export default App;
