import DashboardLayout from "core/layouts/DashboardLayout"
import { useBackNavigate, useCustomNavigate } from "core/utils/helper"
import { RouterName } from "core/router"
import SettingForm from "./SettingForm"

const SettingPage = (props: any) => {
    const navigate = useCustomNavigate()

    const handleGoBack = () => {
        navigate(RouterName.CONTENT_PAGE)
    }

    const handleSubmit = (formData: any) => {
        //console.log("CREAGE PAGE DATA", formData)
    }

    return <>
        <DashboardLayout currentPage="settings" title="Quản lý trang nội dung">
            <div className="row">
                <div className="col-sm-6"><h1>Cài đặt website</h1></div>
                <div className="col-sm-6 text-end">
                    <div className="form-buttons">
                        <button type="button" className="btn btn-create" onClick={()=>{}}><i className="fa-solid fa-check"></i> Lưu cài đặt</button>
                        <span onClick={()=>{}}><a>Xóa dữ liệu</a></span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-9">
                    <SettingForm onSubmit={handleSubmit}/>
                </div>
                <div className="col-sm-3"></div>
            </div>

        </DashboardLayout>
    </>
}

export default SettingPage