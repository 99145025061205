import { documentTitle } from "core/utils/helper"
import { useEffect, useState } from "react"

import logo from 'core/assets/images/poanlogow.svg'
import React from "react";
import { Link } from "react-router-dom";
import { config } from "core/config/app";
import {useDispatch, useSelector} from "react-redux";
import { requestLogout } from "modules/Auth/store/actions";
import KDialog from "../components/Dialog/KDialog";
import {FormControl} from "react-bootstrap";
import InputComponent from "../components/InputComponent";
import {API_CHANGE_PASSWORD} from "../../modules/Auth/api";
import API from "../networks/API";
import {toast} from "react-toastify";

const menuItems = [
    { icon: 'fa-regular fa-compass', label: 'Overview', isDivision: false, url: '/dashboard/overview', path: 'dashboard/overview' },
    { isDivision: true },
    // { icon: 'fa-solid fa-users', label: 'User Manage', isDivision: false, url: '/dashboard/users', path: 'dashboard/users' },
    { icon: 'fa-solid fa-store', label: 'Merchant Manage', isDivision: false, url: '/dashboard/merchants', path: 'dashboard/merchants' },
    // { icon: 'fa-solid fa-box-open', label: 'Post Manage', isDivision: false, url: '/dashboard/posts', path: 'dashboard/posts' },
    {
        icon: 'fa-solid fa-ticket', label: 'Coupon Manage', isDivision: false, url: '', path: 'dashboard/coupon',
        children: [
            {icon: null, label: 'Issue history', isDivision: false, url: '/dashboard/coupon/histories', path: 'dashboard/coupon/histories'},
            {icon: null, label: 'Reconciliation', isDivision: false, url: '/dashboard/coupon/reconciliation', path: 'dashboard/coupon/reconciliation'},
        ]
    },
    {isDivision: true},
    {
        icon: 'fa-solid fa-bullseye', label: 'Marketing', isDivision: false, url: '', path: 'dashboard/marketing',
        children: [
            {icon: null, label: 'Bulk Push Notification', isDivision: false, url: '/dashboard/marketing/bulk-pn', path: 'dashboard/marketing/bulk-pn'},
        ]
    },
    { icon: 'fa-regular fa-comment-alt', label: 'Chat Management', isDivision: false, url: '/dashboard/chat', path: 'dashboard/chat' },
    // { icon: 'fa-solid fa-code-compare', label: 'Setting', isDivision: false, url: '/dashboard/settings' },
    // { isDivision: true },
  ];

const DashboardLayout = (props: any) => {

    const dispatch = useDispatch()
    const redux = useSelector((state:any) => state.Auth)

    const [badge, setBadge] = useState({
        order: 2,
        contact: 2
    })

    useEffect(()=>{
        const title = `Poan Admin - ${props.title ? ""+props.title : "Dashboard"}`
        documentTitle(title)
        //console.log(36, "LOAD PAGE")
    }, [])

    const renderBadge = (item: any) => {
        if(['Liên hệ'].includes(item.label) && badge.contact > 0){
            return <span className="ms-2 badge badge-pill badge-danger bg-danger">{badge.contact}</span>
        }
        if(['Đơn hàng'].includes(item.label) && badge.order > 0){
            return <span className="ms-2 badge badge-pill badge-danger bg-danger">{badge.order}</span>
        }
            
        return <></>
    }

    const handleLogout = () => {
        dispatch(requestLogout())
    }

    const renderMenuItem = (menuItems: any) => {
        return menuItems.map((item: any, index: number) => (
            <React.Fragment key={index}>
                {item.isDivision ? (
                    <li className="division"></li>
                ) : (
                    <li className={props.currentPage?.includes(item.path) ? 'active' : ''}>
                        <Link className={item.url ? 'link' : 'none'} to={config.baseName + (item.url || "")}>
                            <span className="icon"><i className={item.icon}></i></span>
                            <span className="label">{item.label}
                                {/* {item.url} {'/'+(props.currentPage||'')} */}
                                {renderBadge(item)}
                                    </span>
                        </Link>
                        {item.children?.length > 0 && <ul>{renderMenuItem(item.children)}</ul>}
                    </li>
                )}
            </React.Fragment>
        ))
    }

    const [changepassData, setChangePassData] = useState({
        current_password: '',
        password: '',
        password_confirmed: ''
    })

    const [changepassError, setChangePassError] = useState({
        current_password: '',
        password: '',
        password_confirmed: ''
    })

    const [changePassDialog, setChangePassDialog] = useState(false)

    const handleChangeInput = (e: any) => {
        //console.log(e)
        setChangePassData({...changepassData, [e.target.name]: e.target.value})
        setChangePassError({...changepassError, [e.target.name]: ""})
    }

    const handleSubmitChangePassword = async () => {
        try{
            const res = await API.post(API_CHANGE_PASSWORD, changepassData)
            setChangePassDialog(false)
            toast.success("Password changed successfully, please log in again.")
            setTimeout(()=> handleLogout(), 1000)
        } catch (e: any) {
            //console.log(e.response.data.errors)
            setChangePassError({...changepassError, ...e.response.data.errors})
        }
    }

    return <>
        <div className={`dashboard page-${props.id || "empty"}`}>
            <aside>
                <div className="aside-container">
                    <div className="logo text-center">
                        <img src={logo} alt="" width={'80%'} />
                    </div>
                    
                    <ul className="navigation">
                        {renderMenuItem(menuItems)}
                    </ul>
                </div>
            </aside>
            <div className="wrapper">
                <div className="header">
                    <div className="row">
                        <div className="col-sm-6">
                            {/*<h2>{props.title || "Dashboard"}</h2>*/}
                        </div>
                        <div className="col-sm-6 text-end header-menu">
                            {/*<span onClick={()=> {window.open("/", "_blank")}}>*/}
                            {/*    <i className="fa-solid fa-globe"></i> Xem trang*/}
                            {/*</span>*/}
                            {/*<span>|</span>*/}
                            <span>
                                <i className="fa-solid fa-circle-user"></i>
                                {redux.user.username}
                            </span>
                            <span onClick={()=>setChangePassDialog(true)}>
                                Change password
                            </span>
                            <span onClick={handleLogout}>
                                Sign out
                            </span>
                            {/*<span>|</span>*/}
                            {/*<span>*/}
                            {/*    <i className="fa-solid fa-circle-question"></i>*/}
                            {/*</span>*/}
                        </div>
                    </div>
                    
                </div>
                <div className="content">
                    {props.children}
                </div>
            </div>
        </div>

        <KDialog
            variant={'danger'}
            icon={<i className="fa-solid fa-key"></i>}
            title={"Change the admin password"}
            show={changePassDialog}
            overlay={true}
            onCancel={() => {
                setChangePassDialog(false)
            }}
            cancelBtn={"Cancel"}
            onSubmit={()=>{
                handleSubmitChangePassword()
            }}
            submitBtn={"Confirm"}
        >
            <div className="row mb-3 custom-input">
                <div className="mb-3">
                    <InputComponent
                        label={"Current password"}
                        required={true}
                        handleChange={handleChangeInput}
                        type={'password'}
                        input={{
                            name: "current_password",
                            value: changepassData.current_password
                        }}
                        meta={{
                            error: changepassError.current_password,
                            warning: ""
                        }}
                    />
                </div>

                <div className="mb-3">
                    <InputComponent

                        label={"New password"}
                        required={true}
                        handleChange={handleChangeInput}
                        type={'password'}
                        input={{
                            name: "password",
                            value: changepassData.password
                        }}
                        meta={{
                            error: changepassError.password,
                            warning: ""
                        }}
                    />
                </div>
                <div className="mb-3">
                    <InputComponent

                        label={"Confirm new password"}
                        required={true}
                        handleChange={handleChangeInput}
                        type={'password'}
                        input={{
                            name: "password_confirmed",
                            value: changepassData.password_confirmed
                        }}
                        meta={{
                            error: changepassError.password_confirmed,
                            warning: ""
                        }}
                    />
                </div>

            </div>
        </KDialog>
    </>
}

export default DashboardLayout
