const MerchantStatisticTemplate = (props: any) => {
    //console.log(2, props)
    return <>
        <div className="mb-2">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-2">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total merchants: <strong>{props.data?.total}</strong>
                        </div>
                        <div className="col-2">
                            <i className="fa-solid fa-chart-simple text-info"></i> Total waiting for
                            approve: <strong>{props.data?.approved}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default MerchantStatisticTemplate