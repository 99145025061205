import { useConversationDetail } from './../hooks/useConversation'
import { useDownloadMessage } from './../hooks/useDownloadMessage'

const DownloadChatPage = (props: any) => {
  const conversation = useConversationDetail()
  const [ loading ] = useDownloadMessage(conversation, true)

  return null
}

export default DownloadChatPage
