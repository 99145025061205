import { useEffect, useState } from "react"

const useChatHeight = (id = 'message-group') => {
  const [height, setHeight] = useState(0)

  const calcHeight = () => {
    let messageList = document.getElementById(id)
    const pagePadding = 15;
    if (messageList) {
      setHeight(window.innerHeight - messageList?.offsetTop - pagePadding)
    }
  }

  useEffect(() => {
    calcHeight()
  }, []);

  useEffect(() => {
    window.addEventListener('resize', calcHeight)
    
    return () => window.removeEventListener('resize', calcHeight)
  }, [])

  return height
}

export default useChatHeight
