import { Link, useParams } from "react-router-dom";
import { formatDateTime, getImageWithName } from "core/utils/helper";

const ConversationItem = ({ conversation }: any) => {
  const { id } = useParams()

  return <>
    <Link
        to={`/dashboard/chat/${conversation?.conversation_id}`}
        className={`conversation-item ${id == conversation?.conversation_id ? ' active' : ''}`}
    >
      <div className="conversation_thumbnail">
        <div className="avatar"><img src={conversation.avatar_user || getImageWithName(conversation?.user_name)}/></div>
        <div className="avatar"><img src={conversation.avatar_merchant || getImageWithName(conversation?.merchant_shop_name)}/></div>
      </div>
      <div className="conversation_subject">
        <h5>{conversation.user_name} - {conversation.merchant_shop_name}</h5>
        <p>{conversation.last_message || 'sent a file'}</p>
      </div>
      <div className="conversation_time">
        {formatDateTime(conversation.last_message_created_at)}
      </div>
    </Link>
  </>
}

export default ConversationItem
