import { config } from "core/config/app";
import UserList from "./pages/user_management/UserList";
import MerchantList from "./pages/merchant_management/MerchantList";
import MerchantDetail from "./pages/merchant_management/MerchantDetail";
import CouponHistoryPage from "./pages/coupon_management/CouponHistoryPage";
import CouponReconciliationPage from "./pages/coupon_management/CouponReconciliationPage";

import BulkPNPage from "./pages/mkr_bulkpn_management/BulkPNPage";

import BulkPNCampaignDetailPage from "./pages/mkr_bulkpn_management/BulkPNCampaignDetailPage";
import BulkPNCreateCampaignPage from "./pages/mkr_bulkpn_management/BulkPNCreateCampaignPage";



const router =  [
    {
        name: "USER_LIST",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard/users`,
        element: <UserList />
    },

    {
        name: "MERCHANT_LIST",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard/merchants`,
        element: <MerchantList />
    },
    {
        name: "MERCHANT_DETAIL",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard/merchants/:id`,
        element: <MerchantDetail />
    },

    {
        name: "COUPON_HISTORY",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard/coupon/histories`,
        element: <CouponHistoryPage />
    },
    {
        name: "COUPON_RECONCILIATION",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard/coupon/reconciliation`,
        element: <CouponReconciliationPage />
    },
    {
        name: "MKT_BULK_PN_LIST",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard/marketing/bulk-pn`,
        element: <BulkPNPage />
    },

    {
        name: "MKT_BULK_PN_DETAIL",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard/marketing/bulk-pn/:id`,
        element: <BulkPNCampaignDetailPage />
    },

    {
        name: "MKT_BULK_PN_CREATE",
        auth: true,
        exact: true,
        path: `${config?.baseName}/dashboard/marketing/bulk-pn/create`,
        element: <BulkPNCreateCampaignPage />
    },

]
export default router
