const highlight = (text: string, keyword: string) => {

  if (!text || text == '') return '';
  const regex = new RegExp(`(${keyword})`, 'gi');
  const highlightedString = text.replace(regex, match => 
    `<strong class="text-danger">${match}</strong>`
  );

  return highlightedString;

}

const MessageBody = ({ message, formName, param }: any) => {

  return <div className="p-2">
    <div
      className="text"
      dangerouslySetInnerHTML={{
        __html: highlight(message.body, param?.keyword || '')
      }}
    />
  </div>
}

export default MessageBody
