import DataSet from "core/components/DataSet"
import DashboardLayout from "core/layouts/DashboardLayout"
import { useBackNavigate, useCustomNavigate } from "core/utils/helper"
import { RouterName } from "core/router"
import API from "core/networks/API"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import {useDispatch, useSelector} from "react-redux"
import {SubmissionError, updateSyncErrors} from "redux-form";
import CategoryForm from "./CategoryForm";

const initialValues = {
    title: "",
    slug: "",
    template: 'none',
    content: "",
    seo_title: "",
    seo_description: "",
    seo_keyword: "",
    seo_image: "",
    cover_image: "",
    featured_image: "",
    published_at: "20/12/2024 08:00"
}


const CategoryUpdatePage = (props: any) => {
    const commonRedux = useSelector((state: any) => state.Common)
    const navigate = useCustomNavigate()
    const dispatch = useDispatch()

    const [formLoading, setFormLoading] = useState(false)
    const [resetForm, setResetForm] = useState(0)

    const handleGoBack = () => {
        navigate(RouterName.CONTENT_PAGE)
    }

    const handleSubmit = async (formData: any) => {
        //console.log("UPDATE PAGE DATA", {...formData, parent: props.ObjId})
        setFormLoading(true)
        const error = await callCreateCategoryAPI(parseData({...formData, parent: props.ObjId}));
        //console.log(error)
        if(!error){
            setFormLoading(false)
            toast.success("Chỉnh sửa danh mục thành công.")
            props.onCreated()
            setResetForm(resetForm+1)
            // navigate(RouterName.CONTENT_CATEGORY)
        }else{
            setFormLoading(false)
            toast.error("Thêm trang thất bại, vui lòng check các lỗi.")
            throw new SubmissionError({...error})
        }
    }

    const parseData = (formData: any) => {
        let data = formData;
        let template_content = {}
        if(commonRedux.setting?.templates){
            const [template] = commonRedux.setting.templates.filter((item: any) => item.id == formData.template)
            if(template){
                //console.log(48, template)
                if(template.fields.length > 0){
                    for(const field of template.fields){
                        if(formData['template_content_'+field.id]){
                            template_content = {...template_content, [field.id]: data['template_content_'+field.id]}
                            delete data['template_content_'+field.id]
                        }
                    }
                }
            }
        }
        data = {...data, template_content}
        return data;
    }

    const parseValidateError = (errors: any) => {
        let result = {}
        errors.forEach((item: any) => {
            result = {...result, [item.field]: item.error}
        })
        return result;
    }

    const callCreateCategoryAPI = (data: any) => {
        return new Promise(async (resolve, reject) => {
            try{
                const res = await API.post('/cms/v1/content/category', data)
                resolve(false)
            } catch(e: any){
                if(e.response?.data?.errors.length > 0) {
                    resolve(parseValidateError(e.response?.data?.errors))
                }
            }
        })

    }
    useEffect(()=>{
        // toast.success("Thêm trang thành công.")
    }, [])

    useEffect(() => {
        //console.log(props)
    }, [props]);

    return <>
            <CategoryForm
                initialValues={props.initialValues}
                loading={formLoading}
                onSubmit={handleSubmit}
                handleSubmitClick={handleSubmit}
                formReset={resetForm}
                formTitle={props.formTitle}
            />
    </>
}

export default CategoryUpdatePage