import {
    Data, NATIVE_READY,
    REQUEST_GLOBAL_SETTING,
    REQUEST_GLOBAL_SETTING_FAIL,
    REQUEST_GLOBAL_SETTING_SUCCESS, SET_DATASET_COLUMNS, SET_DEBUG_LOG,
    SET_DEBUG_MODE, UPGRADE_REQUIRED, UPLOAD_SUCCESS, UPLOADING,
    FETCH_DATA, FETCH_DATA_SUCCESS, FETCH_DATA_ERROR, UPDATE_DATASET_COLUMNS
} from './types';

export interface DataState {
    setting: any;
    uploading: number;
    upgradeRequired: string|boolean;
    nativeReady: boolean;
    loading: boolean;
    error: Error | null;
    deviceData: any;
    debugMode: number;
    debugLog: any;
    columns: any;
    type: string;
}

const initialState: DataState = {
    setting: [],
    uploading: 0,
    upgradeRequired: false,
    nativeReady: false,
    deviceData: {},
    loading: false,
    error: null,
    debugMode: 0,
    debugLog: "",
    columns: {},
    type: ""
};

const CommonReducer = (
    state: DataState = initialState,
    action: any,
): DataState => {
    ////console.log(22, action.type)
    switch (action.type) {
        
        case REQUEST_GLOBAL_SETTING:
            return { ...state, loading: true, type: action.type, error: null };
        case REQUEST_GLOBAL_SETTING_SUCCESS:
            return { ...state, loading: false, type: action.type, setting: action.payload?.data, error: null };
        case REQUEST_GLOBAL_SETTING_FAIL:
            return { ...state, loading: false, type: action.type, error: null };
        case SET_DEBUG_MODE:
            return { ...state, debugMode: state.debugMode+1, loading: false, type: action.type, error: null };
        case SET_DEBUG_LOG:
            return { ...state, debugLog: JSON.stringify(action.payload), type: action.type, loading: false, error: null };
        case UPLOADING:
            return { ...state, uploading: state.uploading+1, type: action.type, loading: false, error: null };
        case UPLOAD_SUCCESS:
            return { ...state, uploading: state.uploading-1, type: action.type, loading: false, error: null };
        case UPGRADE_REQUIRED:
            return { ...state, upgradeRequired: action.payload, type: action.type, loading: false, error: null };
        case NATIVE_READY:
            return { ...state, nativeReady: true, deviceData: action.payload, type: action.type, loading: false, error: null };
        case SET_DATASET_COLUMNS:
            return { ...state, columns: {...state.columns, [action.storeName]: action.payload}, loading: false, error: null };
        case UPDATE_DATASET_COLUMNS:
            return { ...state, columns: {...state.columns, ...action.payload}, loading: false, error: null };
        default:
            return state;
    }
};

export default CommonReducer