import { useEffect, useState, useMemo } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { requestFetchMessage } from "../store/actions"
import { updatePaginate } from "core/store/actions";
import { reset, change } from 'redux-form';

export const formName = 'messageMgmt'
const LIMIT = 10

const scrollToBottom = (elmId: string) => {
  let messageList = document.getElementById(elmId)
  if (messageList) {
    messageList.scrollTop = messageList.scrollHeight;
  }
}

export const scrollToDiv = (id: string, time = 100) => {
  setTimeout(() => {
    if (id && document.getElementById(id)) {
      document.getElementById(id)?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
    }
  }, time)
}

export const useMessageScroll = (elmId = 'message-list') => {
  const { id } = useParams()
  const redux = useSelector((state: any) => state.Chat)
  const reduxDispatch = useDispatch()
  const paginateRedux = useSelector((state: any) => state.paginate[formName])
  const location = useLocation()
  const locationSearch = new URLSearchParams(location.search);

  const onScroll = (e: any) => {
    // scroll to top
    if (e.target.scrollTop <= 0) {
      reduxDispatch(updatePaginate(formName, {
        last_id: redux.messages?.data[0]?.id || 0,
        id: null,
        limit: LIMIT,
      }))
    }

    // scroll to bottom
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
      if (locationSearch.get('search_id')) {
        let newestId = redux.messages.data[redux.messages.data.length - 1]?.id
        reduxDispatch(updatePaginate(formName, {
          last_id: newestId || 0,
          id: null,
          limit: LIMIT,
          scroll_type: 'up',
        }))
      }
    }
  }

  useEffect(() => {
    let messageList = document.getElementById(elmId)
    if (paginateRedux?.last_id && paginateRedux?.scroll_type === 'down' && messageList) {
      messageList.scrollTo(0, 150);
    }
  }, [redux?.messages?.data?.length])

  useEffect(() => {
    if (
      redux.messages.data.length
      && (!paginateRedux?.last_id || paginateRedux?.last_id === 0)
      && !locationSearch.get('search_id')
    ) {
      scrollToBottom(elmId)
    }

    let messageList = document.getElementById(elmId)
    if (messageList) {
      messageList.addEventListener('scroll', onScroll)
    }
    
    return () => {
      if (messageList) {
        messageList.removeEventListener('scroll', onScroll)
      }
    }

  }, [id, redux.messages, locationSearch.get('search_id')])

  useEffect(() => {
    if (locationSearch.get('search_id')) {
      if (
        redux.messages.data.length
        && (!paginateRedux?.last_id || paginateRedux?.last_id === 0)
      ) {
        scrollToDiv('message-' + locationSearch.get('search_id'))
      }
    }
  }, [redux.messages])

  return null
}

export const useMessageParam = () => {
  const filterRedux = useSelector((state: any) => state.form[formName]?.values)
  const paginateRedux = useSelector((state: any) => state.paginate[formName])
  const reduxDispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const locationSearch = new URLSearchParams(useLocation().search);
  const [param, setParam] = useState({
    order_by: 'id',
    order_dir: 'DESC',
    limit: LIMIT,
    last_id: null,
    scroll_type: 'down',
    keyword: '',
    id: '',
  })
  useMessageScroll()
  const { id: conversation_id } = useParams()
  const search_id = locationSearch.get('search_id')

  useEffect(() => {
    if (paginateRedux) {
      setParam((p: any) => ({
        ...(p || {}),
        limit: paginateRedux?.limit || 20,
        last_id: paginateRedux?.last_id,
        id: paginateRedux?.id,
        scroll_type: paginateRedux?.scroll_type,
      }))
    }
  }, [paginateRedux])

  useEffect(() => {
    if (filterRedux) {
      setParam((p: any) => ({
        ...(p || {}),
        keyword: filterRedux?.keyword,
        order_by: filterRedux?.order_by,
        order_dir: filterRedux?.order_dir,
      }))

      reduxDispatch(updatePaginate(formName, {
        last_id: null,
        limit: LIMIT,
        scroll_type: 'down',
      }))
    }
  }, [filterRedux])

  // if has new keyword
  // clear old keyword in url
  useEffect(() => {
    if (filterRedux?.keyword) {
      navigate(location.pathname)
    }
  }, [filterRedux?.keyword])

  useEffect(() => {
    reduxDispatch(updatePaginate(formName, {
      last_id: null,
      limit: LIMIT,
      scroll_type: 'down',
      id: '',
    }))
    reduxDispatch(reset(formName))
  }, [conversation_id])

  useEffect(() => {
    if (search_id) {
      reduxDispatch(updatePaginate(formName, {
        last_id: null,
        limit: 5,
        scroll_type: 'down',
        id: search_id,
      }))
      reduxDispatch(reset(formName))
    }
  }, [search_id])

  const selectMessage = (message: any) => {
    if (filterRedux?.keyword) {
      navigate(location.pathname + `?search_id=${message.id}&search_text=${filterRedux?.keyword}`)
    }
  }

  const resetPaginate = () => {
    reduxDispatch(updatePaginate(formName, {
      last_id: null,
      limit: LIMIT,
      scroll_type: 'down',
      id: '',
    }))
  }

  return {
    param,
    selectMessage,
    resetPaginate,
  }
}

export const useMessage = (param: any) => {
  const { id: conversation_id } = useParams()
  const reduxDispatch = useDispatch()
  const redux = useSelector((state: any) => state.Chat)

  useEffect(() => {
    if (conversation_id) {
      reduxDispatch(requestFetchMessage({...param, conversation_id}))
    }
  }, [param, conversation_id])

  return redux.messages
}
