import DashboardLayout from "core/layouts/DashboardLayout"
import DataSet from "core/components/DataSet"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {fetchPawnDataList, initPawnDataList} from "../../store/actions";
import {formatDateTime, parseFilterSelect, setParamsUrl} from "core/utils/helper";
import CouponHistoryListRowTemplate from "./CouponHistoryListRowTemplate";
import CouponHistoryFilter from "./CouponHistoryFilter";
import CouponStatisticTemplate from "./CouponStatisticTemplate";
import {
    API_ADMIN_MERCHANT,
    API_COUPON_HISTORIES,
    API_COUPON_HISTORIES_STATISTIC,
    API_ISSUE_COUPON,
    API_COUPON_LIST,
} from "../../api";
import KDialog from "../../../../core/components/Dialog/KDialog";
import {toast} from "react-toastify";
import API from "../../../../core/networks/API";
import "modules/Pawn/assets/scss/coupon.scss"
import moment from "moment/moment";
import CouponReconciliationStatisticTemplate from "./CouponReconciliationStatisticTemplate";
import {useQuery} from "../../../../core/hooks/useQuery";

let reduxFuncName = 'couponHistoryMgmt'
const CouponHistoryPage = (props: any) => {
    const dispatch = useDispatch()
    const filterRedux = useSelector((state: any) => state.form[reduxFuncName]?.values)
    const paginateRedux = useSelector((state: any) => state.paginate[reduxFuncName])

    const [issueDialog, setIssueDialog] = useState(false)
    const [issueData, setIssueData] = useState({
        phone: "",
        couponId: ""
    })
    const [issueError, setIssueError] = useState({
        phone: "",
        couponId: ""
    })

    const [couponDetailDialog, setCouponDetailDialog] = useState(false)
    const [couponDetail, setCouponDetail] = useState<any>(null)

    const defaultStatistic = {
        "total": 0,
        "available": 0,
        "used": 0,
        "using": 0,
        "validating": 0,
        "expired": 0
    }
    const [statisticData, setStatisticData] = useState({...defaultStatistic})
    const [coupons, setCoupon] = useState<any>([])

    const queryParams = useQuery()
    const [queryParamsState, setQueryParamsState] = useState<any>({
        status: queryParams.get("status") || "",
        phone: queryParams.get("phone") || "",
        coupon: queryParams.get("coupon") || "",
        issuedFrom: "",
        issuedTo: "",
        relations: 'coupon,user,issuedBy,settlementBy,merchant,property',
        page: queryParams.get("page") || "",
        page_size: queryParams.get("page_size") || ""
    })

    const parseFilter = () => {
        console.log(35, queryParamsState)
        return queryParamsState;
    }

    const setFilter = () => {
        const filter: any = {
            status: parseFilterSelect(filterRedux?.status),
            keyword: filterRedux?.keyword,
            phone: filterRedux?.phone,
            coupon: filterRedux?.coupon,
            issuedFrom: filterRedux?.date?.startDate,
            issuedTo: filterRedux?.date?.endDate,
            relations: 'coupon,user,issuedBy,settlementBy,merchant,property',
            page: paginateRedux?.page,
            page_size: paginateRedux?.page_size
        }
        setQueryParamsState(filter)
        setParamsUrl(filter)
        return filter;
    }

    const getDefaultFilterValue = () => {
        return {
            status: queryParams.get("status") || "",
            keyword: queryParams.get("keyword"),
            phone: queryParams.get("phone"),
            coupon: queryParams.get("coupon"),
            date: {
                startDate: queryParams.get("issuedFrom"),
                endDate: queryParams.get("issuedTo")
            }
        }
    }

    const callFetchData = () => {
        dispatch(fetchPawnDataList(reduxFuncName, 'GET', API_COUPON_HISTORIES, parseFilter()))
    }

    const callFetchStatisticData = async() => {
        try{
            setStatisticData(defaultStatistic)
            const res = await API.get(API_COUPON_HISTORIES_STATISTIC, parseFilter())
            //console.log(53, res)
            if(res.data?.data) setStatisticData(res.data.data)
        } catch (e) {
            console.error(e)
        }
    }

    const callFetchCouponList = async() => {
        try{
            const res = await API.get(API_COUPON_LIST, {
                state: 'ENABLED',
                page_size: 0,
            })
            //console.log(79, res)
            if(res.data?.data) {
                setCoupon(res.data.data)
                setIssueData((p: any) => ({...p, couponId: res.data.data[0]?.id || ''}))
            }
        } catch (e) {
            console.error(e)
        }
    }

    const callDeleteData = async (ids: any) => {

    }

    const handleChangeFilter =(data: any) => {
        setFilter()
    }

    const handleBulkDelete = (ids: any) => {
        //console.log(32, ids)
        callDeleteData(ids)
    }

    const handleIssueCoupon = () => {
        //console.log("HANDLE ISSUE COUPON")
        setIssueDialog(true)
    }

    const handleSubmitIssueForm = async () => {
        try{
            //console.log(issueData)
            const res = await API.post(API_ISSUE_COUPON, issueData)
            toast.success("Successful coupon issuance")
            setIssueDialog(false)
            callFetchData()
        } catch (e: any) {
            //console.log(e.response)
            // setIssueError({phone: "Issuing coupons failed, undefined error", couponId: ""})
            if([400, 422].includes(e.response.status)){
                setIssueError({phone: e.response.data.msg, couponId: ""})
            } else{
                toast.error("Issuing coupons failed, undefined error")
                setIssueDialog(false)
            }
        }

    }

    const handleChangeIssuePhone = (e: any) => {
        setIssueData((p: any) => ({...p, phone: e.target.value}))
    }

    const handleChangeIssueCoupon = (e: any) => {
        setIssueData((p: any) => ({...p, couponId: e.target.value}))
    }

    const handleDetail = (item: any) => {
        //console.log("DETAIL", item)
        setCouponDetail(item)
        setCouponDetailDialog(true)
    }


    useEffect(() => {
        if(paginateRedux) {
            if(paginateRedux.page != queryParamsState.page || paginateRedux.page_size != queryParamsState.page_size){
                setFilter()
            }
        }
    }, [paginateRedux]);

    useEffect(() => {
        callFetchData()
        callFetchStatisticData()
        callFetchCouponList()
    }, []);

    useEffect(() => {
        callFetchData()
        callFetchStatisticData()
    }, [queryParamsState]);

    return <>
        <DashboardLayout currentPage="dashboard/coupon/histories" title="Coupon Issue history">
            <h1>COUPON ISSUE HISTORIES</h1>
            <CouponHistoryFilter
                filterName={reduxFuncName}
                onSubmit={handleChangeFilter}
                onCreate={handleIssueCoupon}
                initialValues={getDefaultFilterValue()}
            />
            <div className="mt-5">
                <CouponStatisticTemplate data={statisticData}/>
            </div>

            <DataSet
                // statisticTemplate={CouponStatisticTemplate}
                filterName={reduxFuncName}
                module={'Pawn'}
                columnKey={reduxFuncName}
                pagination={{page: queryParams.get("page") || 1, page_size: queryParams.get("page_size") || 20}}
                columnTemplate={[
                    {label: "Coupon Code", key: "coupon_code", state: true, sort: false},
                    {label: "User phone", key: "user.phone", state: true, sort: false},
                    {label: "Issued by", key: "issued_by", state: true, sort: false},
                    {label: "Issued at", key: "issued_at", state: true, sort: false},
                    {label: "Expired at", key: "expired_at", state: true, sort: false},
                    {label: "Validated at", key: "validated_at", state: true, sort: false},
                    {label: "Actived at", key: "actived_at", state: true, sort: false},
                    {label: "Used confirmed at", key: "approved_at", state: true, sort: false},
                    {label: "Confirm used by", key: "merchant", state: true, sort: false},
                    {label: "State", key: "state", state: true, sort: false},
                ]}
                onEdit={handleDetail}
                onDelete={handleBulkDelete}
                rowTemplate={CouponHistoryListRowTemplate}
            />


            <KDialog
                variant={'warning'}
                icon={<i className="fa-solid fa-ticket"></i>}
                title={"Issue coupon"}
                show={issueDialog}
                overlay={true}
                onCancel={() => {
                    setIssueData({phone: "", couponId: coupons[0]?.id || ''})
                    setIssueError({phone: "", couponId: ""})
                    setIssueDialog(false)
                }}
                cancelBtn={"Cancel"}
                onSubmit={handleSubmitIssueForm}
                submitBtn={"Confirm"}
            >
                <div className="row mb-3 custom-input">
                    <div className="col-sm-12">
                        <label>User Phone:</label>
                        <input onChange={handleChangeIssuePhone} name={"phone"} className="form-control" placeholder={"Please enter the user phone number"}/>
                        {issueError.phone && <p className="error ps-2">{issueError.phone}</p>}
                    </div>
                </div>
                <div className="row mb-3 custom-input">
                    <div className="col-sm-12">
                        <label>Select Coupon:</label>
                        <select className="form-control" onChange={handleChangeIssueCoupon}>
                            {coupons.map((coupon: any) => (
                                <option value={coupon.id}>{coupon.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </KDialog>
            <KDialog
                variant={'info coupon_detail'}
                icon={<i className="fa-solid fa-ticket"></i>}
                title={"Coupon Detail"}
                show={couponDetailDialog}
                overlay={true}
                onCancel={() => {
                    setCouponDetailDialog(false)
                    setCouponDetail(null)
                }}
                cancelBtn={"Close"}
                onSubmit={null}
                submitBtn={""}
            >
                <table className={'table table-bordered'}>
                    <tr>
                        <th style={{width: '20%'}}>Coupon Code</th>
                        <td>{couponDetail?.coupon_code}</td>
                        <th style={{width: '20%'}}>Coupon Name</th>
                        <td>{couponDetail?.coupon?.name}</td>
                    </tr>
                    <tr>
                        <th>Issued at</th>
                        <td>{formatDateTime(couponDetail?.issued_at)}</td>
                        <th>Issued by</th>
                        <td>{couponDetail?.issued_by?.username}</td>
                    </tr>
                    <tr>
                        <th>Expired at</th>
                        <td>{formatDateTime(couponDetail?.expired_at)}</td>
                        <th>State</th>
                        <td>{couponDetail?.state_label}</td>
                    </tr>
                    <tr className={'division'}>
                        <td colSpan={2}></td>
                    </tr>
                    <tr>
                        <th>Validate at</th>
                        <td>{formatDateTime(couponDetail?.validated_at)}</td>
                        <th></th>
                        <td></td>
                    </tr>
                    <tr>
                        <th>Validate for properties</th>
                        <td colSpan={3}>
                            {couponDetail?.property_id ? <>[#{couponDetail?.property?.id}] {couponDetail?.property?.name}</> : <>--</>}
                        </td>
                    </tr>
                    <tr className={'division'}>
                        <td colSpan={2}></td>
                    </tr>
                    <tr>
                        <th>Active at</th>
                        <td>{formatDateTime(couponDetail?.actived_at)}</td>
                        <th></th>
                        <td></td>
                    </tr>
                    <tr>
                        <th>Active for merchant</th>
                        <td colSpan={3}>
                            {couponDetail?.approved_at || couponDetail?.actived_at
                                ? <>[#{couponDetail?.merchant?.id}] {couponDetail?.merchant?.name}</>
                                : <>--</>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>Confirmed at</th>
                        <td>{formatDateTime(couponDetail?.approved_at)}</td>
                        <th></th>
                        <td></td>
                    </tr>
                </table>
            </KDialog>
        </DashboardLayout>
    </>
}

export default CouponHistoryPage
