export const requestFetchStatistic = (payload: any) => ({
  type: "REQUEST_FETCH_STATISTIC",
  payload
})

export const requestFetchStatisticSuccess = (payload: any) => ({
  type: "REQUEST_FETCH_STATISTIC_SUCCESS",
  payload
})

export const requestFetchStatisticFailed = (payload: any) => ({
  type: "REQUEST_FETCH_STATISTIC_FAILED",
  payload
})

//
export const requestFetchTotalStatistic = (payload: any) => ({
  type: "REQUEST_FETCH_TOTAL_STATISTIC",
  payload
})

export const requestFetchTotalStatisticSuccess = (payload: any) => ({
  type: "REQUEST_FETCH_TOTAL_STATISTIC_SUCCESS",
  payload
})

export const requestFetchTotalStatisticFailed = (payload: any) => ({
  type: "REQUEST_FETCH_TOTAL_STATISTIC_FAILED",
  payload
})

//
export const requestFetchUserStatistic = (payload: any) => ({
  type: "REQUEST_FETCH_USER_STATISTIC",
  payload
})

export const requestFetchUserStatisticSuccess = (payload: any) => ({
  type: "REQUEST_FETCH_USER_STATISTIC_SUCCESS",
  payload
})

export const requestFetchUserStatisticFailed = (payload: any) => ({
  type: "REQUEST_FETCH_USER_STATISTIC_FAILED",
  payload
})
//
export const requestFetchMerchantStatistic = (payload: any) => ({
  type: "REQUEST_FETCH_MERCHANT_STATISTIC",
  payload
})

export const requestFetchMerchantStatisticSuccess = (payload: any) => ({
  type: "REQUEST_FETCH_MERCHANT_STATISTIC_SUCCESS",
  payload
})

export const requestFetchMerchantStatisticFailed = (payload: any) => ({
  type: "REQUEST_FETCH_MERCHANT_STATISTIC_FAILED",
  payload
})
//
export const requestFetchPostStatistic = (payload: any) => ({
  type: "REQUEST_FETCH_POST_STATISTIC",
  payload
})

export const requestFetchPostStatisticSuccess = (payload: any) => ({
  type: "REQUEST_FETCH_POST_STATISTIC_SUCCESS",
  payload
})

export const requestFetchPostStatisticFailed = (payload: any) => ({
  type: "REQUEST_FETCH_POST_STATISTIC_FAILED",
  payload
})
