import DateTimePickerComponent from "core/components/DateTimePickerComponent"
import DateTimePicker from "core/components/DateTimePickerComponent"
import FilePickerComponent from "core/components/FilePickerComponent"
import FormSection from "core/components/FormSection"
import ImageOptionComponent from "core/components/ImageOptionComponent"
import InputComponent from "core/components/InputComponent"
import RTEComponent from "core/components/RTE/RTEComponent"
import SEOPreview from "core/components/SEOPreview"
import SelectComponent from "core/components/SelectComponent"
import { useSelector } from "react-redux"
import { Field, reduxForm } from "redux-form"

let formName = "settingForm"

const SettingForm = (props: any) => {
    const formData = useSelector((state: any) => state.form[formName])
    const { error, handleSubmit, pristine, reset, submitting, dispatch } = props

    const handleChangeForm = () => {
        //console.log(formData)
    }

    const handleSubmitForm = () => {

    }

    const handlResetForm = () => {
        dispatch(reset(formName)); 
    }

    return <>
        <form className="form-panel" onChange={handleChangeForm}>
            <div className="form-left-panel">
                <FormSection sectionHeading="Thông tin hiển thị" sectionDesc="Nhập thông tin hiển thị website">
                    <div className="mb-3">
                        <Field
                            name="site_name"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="Tên website"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="site_tagline"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="Tiêu đề website"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="status"
                            type="hidden"
                            dataSet={[
                                { value: 'ALL', label: 'Tất cả' },
                                { value: 'PUBLISHED', label: 'Xuất bản' },
                                { value: 'DRAFT', label: 'Nháp' },
                            ]}
                            defaultValue={{ value: 'ALL', label: 'Tất cả' }}
                            isMulti={false}
                            disabled={submitting}
                            component={SelectComponent}
                            label="Trang chủ"
                            handleDispatch={dispatch}
                            formName="filterPageManagement"
                        />
                    </div>
                </FormSection>
                <FormSection sectionHeading="Cài đặt giao diện" sectionDesc="Cài đặt giao diện">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <Field
                                    name="site_logo"
                                    type="text"
                                    disabled={submitting}
                                    component={FilePickerComponent}
                                    label="Site Logo"
                                    height={"150px"}
                                    width={"150px"}
                                    objectFit="contain"
                                    formName={formName}
                                    handleDispatch={dispatch}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                        <div className="mb-3">
                                <Field
                                    name="site_favicon"
                                    type="text"
                                    disabled={submitting}
                                    component={FilePickerComponent}
                                    label="Site Icon"
                                    height={"150px"}
                                    width={"150px"}
                                    objectFit="contain"
                                    formName={formName}
                                    handleDispatch={dispatch}
                                />
                            </div>
                        </div>
                    </div>
                    
                </FormSection>
                <FormSection sectionHeading="Cài đặt liên hệ" sectionDesc="Cài đặt thông tin liên hệ">
                    
                    <div className="mb-3">
                        <Field
                            name="site_email"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="Email website"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="site_hotline"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="Hotline"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="site_address"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="Địa chỉ"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="site_facebook"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="Facebook URL"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="site_messenger"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="Messenger URL"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="site_tiktok"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="Tiktok URL"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="site_youtube"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="Youtube URL"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                </FormSection>
                <FormSection sectionHeading="Cài đặt SMTP" sectionDesc="Cấu hình gửi mail">
                    <div className="mb-3">
                        <Field
                            name="smtp_host"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="SMTP Host"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="smtp_port"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="SMTP Port"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="smtp_encrypt"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="SMTP Encrypt"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="smtp_username"
                            type="text"
                            disabled={submitting}
                            component={InputComponent}
                            label="SMTP Host"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                    <div className="mb-3">
                        <Field
                            name="smtp_password"
                            type="password"
                            disabled={submitting}
                            component={InputComponent}
                            label="SMTP Password"
                            formName={formName}
                            handleDispatch={dispatch}
                        />
                    </div>
                </FormSection>
                
            </div>
        </form>
    </>
}

export default reduxForm({
    form: formName
})(SettingForm)