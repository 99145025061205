import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { change } from "redux-form"

const ImageOptionComponent = (props: any) => {
    const formData = useSelector((state: any) => state.form[props.formName])
    // const [dataSet, setDataSet] = useState<any>([])

    const handleChange = (e: any) => {
        // //console.log(e.target)
        props.handleDispatch(change(props.formName, props.input.name, e.target.value))
    }

    const checkValue = (item: any) => {
        // //console.log(formData)//, formData?.values[props.input.name])
        if(formData?.values){
            // //console.log(18, formData?.values[props.input.name])
            if(item.id == formData?.values[props.input.name]) return true;
            // return true;
        }else{
            // //console.log(21, item.id, props.defaultValue)
            // if(item.id == props.defaultValue) return true;
            props.handleDispatch(change(props.formName, props.input.name, props.defaultValue))
        }
        return false;
    }

    useEffect(()=>{
        // setDataSet(props.dataSet)
        // //console.log(16, props)
    }, [props])

    return <>
        <div className="custom-input">
            <label>{props.label}</label>
            {/* <input {...props.input} disabled={props.disabled} readOnly={props.readOnly} type={props.type} /> */}
            <div className="image-option-container" onChange={handleChange}>
                {props.dataSet.length > 0 && props.dataSet.map((item: any, k:number) => {
                    
                    return <label className="image-option-item" key={k}>
                        <input checked={checkValue(item)} name={props.input.name} type={props.type} value={item.id} onChange={()=>{}}/>
                        <div className="image-option-image">
                            <img src={item.image} alt="" />
                            <span>{item.label}</span>
                        </div>
                    </label>
                })}
                {props.disabled && <div className="disabled"></div>}
            </div>
            {props.meta.error && <p className="error">{props.meta.error}</p>}
            {props.meta.warning && <p className="warning">{props.meta.warning}</p>}
        </div>
    </>
}

export default ImageOptionComponent