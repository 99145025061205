import { config } from "core/config/app";
import Lottie from "lottie-react";
import loadingFile from 'core/assets/lotties/404.json'

const NotFound = () => {
  ////console.log(window.location);
  
  return (
    <>
      <div className="splash-screen">
            {/* <Loading type="spinningBubbles" color="#3D9700"/> */}
            <div className="loading">
                <Lottie animationData={loadingFile} loop={true} />
            </div>
        </div>
    </>
  )
}

export default NotFound;