import { useParams } from "react-router-dom"
import DashboardLayout from "core/layouts/DashboardLayout"
import ConversationFilter from './../components/ConversationFilter'
import ConversationList from './../components/ConversationList'
import MessageList from './../components/MessageList'
import MessageFilter from './../components/MessageFilter'
import ConversationInfo from './../components/ConversationInfo'
import {
  formName as formConversationName,
  useConversationParam
} from './../hooks/useConversation'
import {
  formName as formMessageName,
  // useMessageParam
} from './../hooks/useMessage'

const ChatPage = (props: any) => {
  const { id } = useParams()
  const { param: conversationParam } = useConversationParam()

  return <>
    <DashboardLayout currentPage="dashboard/chat" title="Chat management">
      <h1>CHAT MANAGEMENT</h1>
      <div className="chat-container">
        <div className="row" id="chat-filter">
          <div className="col-sm-4">
            <div className="row border h-100 mb-3 pt-2">
              <div className="col-sm-12">
                <ConversationFilter
                    filterName={formConversationName}
                    initialValues={conversationParam || {}}
                />
              </div>
            </div>
          </div>
          <div className="col-8 p-0">
            <div className="w-100 d-flex h-100">
              <div style={{width: "70%"}} className="border">
                <div className="pb-4 ps-3 pe-3 mt-2">
                  <MessageFilter
                      filterName={formMessageName}
                      initialValues={{}}
                  />
                </div>
              </div>
              <div style={{width: "30%"}}>
                <div className="border h-100">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
              className="col-sm-4 border p-0 position-relative"
              id="conversation-group"
          >
            <ConversationList/>
          </div>
          <div className="col-sm-8 p-0">
            <div className="w-100 d-flex">
              <div
                  className="border p-0 position-relative conversation-messages"
                  id="message-group"
                  style={{width: "70%"}}
              >
                <MessageList formName={formMessageName}/>
              </div>
              <div style={{width: "30%"}}>
                <div className="border h-100">
                  {id ? <ConversationInfo/> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </DashboardLayout>
  </>
}

export default ChatPage
