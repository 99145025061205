import { useEffect, useRef, useState } from "react"
import { change } from "redux-form"

const KeywordComponent = (props: any) => {

    const [keywords, setKeywords] = useState<string[]>([])

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // //console.log(e)
        if(e.code == "Enter" || e.code == 'Comma'){
            if(e.currentTarget.value.length >= (props.minCharacter || 3)){
                setKeywords([...keywords, e.currentTarget.value.replace(',', '')])
                e.currentTarget.value = ''
            }
        }
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if(e.currentTarget.value.length >= (props.minCharacter || 3)){
            setKeywords([...keywords, e.currentTarget.value.replace(',', '')])
            e.currentTarget.value = ''
        }
    }

    const removeKeyword = (k: number) => {
        //console.log(k)
        let _keywords = [...keywords]
        _keywords.splice(k, 1)
        //console.log(_keywords)
        setKeywords(_keywords)
    }


    useEffect(()=>{
        //console.log(5, props.meta.initial)
        if(props.meta.initial){
            setKeywords( props.meta.initial.split(","))
        }
            // setKeywords( props.meta.intial.split(','))
    }, [])


    useEffect(()=>{
        //console.log(5, props.meta.initial)
        if(props?.input?.value){
            setKeywords(props?.input?.value.split(","))
        } else {
            setKeywords([])
        }
    }, [props?.input?.value])

    useEffect(()=>{
        //console.log(43, keywords)
        props.handleDispatch(change(props.formName, props.input.name, keywords.join(',')))
    }, [keywords])

    return <>
        <div className="custom-input keyword-filter">
            <label>{props.label}</label>
            <div className="keyword-container">
                {keywords.length ? <div className="keyword-list">
                    {keywords.map((item: string, k: number) => (
                        <div className="keyword-item" key={k}>
                            <span>{item}</span>
                            <i onClick={()=>removeKeyword(k)} className="fa-solid fa-xmark"></i>
                        </div>
                    ))}
                    
                </div> : null}
                <input className={keywords.length ? 'has-keyword' : ''} placeholder="Input keyword..." onKeyUp={onKeyUp} onBlur={onBlur}/>
            </div>
            
            
            <input {...props.input} disabled={props.disabled} readOnly={props.readOnly} type={props.type}/>
            {props.meta.error && <p className="error">{props.meta.error}</p>}
            {props.meta.warning && <p className="warning">{props.meta.warning}</p>}
        </div>
    </>
}

export default KeywordComponent
