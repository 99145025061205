import API from "core/networks/API";
import { call, put, takeLatest, delay } from "redux-saga/effects";
import {
  requestFetchConversationFail,
  requestFetchConversationSuccess,
  requestFetchMessageFail,
  requestFetchMessageSuccess,
  requestFetchConversationDetailFail,
  requestFetchConversationDetailSuccess,
} from "./actions";
import { API_ADMIN_CONVERSATION, API_ADMIN_MESSAGE, API_ADMIN_CONVERSATION_DETAIL } from "../api";
import { route } from 'core/router'

function* fetchConversation(_action: any): any {
  yield delay(100)
  try {
    const response = yield call(() => {
      return API.get(API_ADMIN_CONVERSATION, _action.payload);
    });
    const { data } = response;
    
    if (data?.code === 200) {
      yield put(requestFetchConversationSuccess(data));
    }else{
      yield put(requestFetchConversationFail(data));
    }
  } catch (error) {
    yield put(requestFetchConversationFail(error));
  }
}

function* fetchMessage(_action: any): any {
  yield delay(300)
  try {
    const response = yield call(() => {
      return API.get(route(API_ADMIN_MESSAGE, _action.payload.conversation_id), _action.payload);
    });
    const { data } = response;
    
    if (data?.code === 200) {
      yield put(requestFetchMessageSuccess({data}));
    }else{
      yield put(requestFetchMessageFail(data));
    }
  } catch (error) {
    yield put(requestFetchMessageFail(error));
  }
}

function* fetchConversationDetail(_action: any): any {
  yield delay(100)
  try {
    const response = yield call(() => {
      return API.get(route(API_ADMIN_CONVERSATION_DETAIL, _action.payload), _action.payload);
    });
    const { data } = response;
    
    if (data?.code === 200) {
      yield put(requestFetchConversationDetailSuccess({data}));
    }else{
      yield put(requestFetchConversationDetailFail(data));
    }
  } catch (error) {
    yield put(requestFetchConversationDetailFail(error));
  }
}

export default function* ChatSaga() {
  yield takeLatest("REQUEST_FETCH_CONVERSATION", fetchConversation)
  yield takeLatest("REQUEST_FETCH_MESSAGE", fetchMessage)
  yield takeLatest("REQUEST_FETCH_CONVERSATION_DETAIL", fetchConversationDetail)
}
