import DateTimePickerComponent from "core/components/DateTimePickerComponent"
import DateTimePicker from "core/components/DateTimePickerComponent"
import FilePickerComponent from "core/components/FilePickerComponent"
import FormSection from "core/components/FormSection"
import ImageOptionComponent from "core/components/ImageOptionComponent"
import InputComponent from "core/components/InputComponent"
import RTEComponent from "core/components/RTE/RTEComponent"
import SEOPreview from "core/components/SEOPreview"
import { useSelector } from "react-redux"
import { Field, InjectedFormProps, reduxForm } from "redux-form"
import defaultImage from 'core/assets/images/default-image.png'
import {ReactNode, useEffect, useState} from "react"
import { toast } from "react-toastify"
import TextAreaComponent from "core/components/TextAreaComponent";

let formName = "categoryForm"

interface CustomProps {
    handleSubmitClick: Function;
    loading: boolean,
    formTitle?: ReactNode,
    formReset: number,
}

const CategoryForm: React.FC<CustomProps & InjectedFormProps<{}, CustomProps>> = (props: any) => {
    const commonRedux = useSelector((state: any) => state.Common)
    const formData = useSelector((state: any) => state.form[formName])
    const { error, handleSubmit, pristine, reset, submitting, dispatch, dirty } = props
    const [loading, setLoading] = useState(false)
    const [formResetTime, setFormResetTime] = useState(props.formReset)

    const handleChangeForm = (e: any) => {
        //console.log(25, formData)
    }

    const handleSubmitForm = () => {
        
    }

    const handlResetForm = () => {
        dispatch(reset(formName)); 
    }

    const renderDataSet = () => {
        if(commonRedux.setting?.templates && commonRedux.setting.templates.length > 0)
            return [
                    {id: 'none', label: "Không áp dụng", image: defaultImage},
                    ...commonRedux.setting.templates.map((template: any) => ({id: template.id, label: template.label, image: defaultImage}))]
        return []
    }

    const renderContent = () => {
        
        if(formData?.values?.template){
            const [templateData] = commonRedux.setting.templates.filter((item: any) => item.id == formData.values.template)
            // //console.log(44, templateData)
            let contentEls: any;
            if(templateData){
                if(templateData.fields.length > 0){
                    contentEls = templateData.fields.map((item: any, k: number) => {
                        if(['content'].includes(item.type)){
                            return <div className="mb-3">
                                    <Field
                                        name="content"
                                        type="text"
                                        disabled={loading}
                                        component={RTEComponent}
                                        label=""
                                        formName={formName}
                                        handleDispatch={dispatch}
                                        
                                    />
                                </div>
                        }
                        if(['html'].includes(item.type)){
                            return <div className="mb-3">
                                    <Field
                                        name={`template_content_${item.id}`}
                                        type="text"
                                        disabled={loading}
                                        component={RTEComponent}
                                        label={item.label}
                                        formName={formName}
                                        handleDispatch={dispatch}
                                        
                                    />
                                </div>
                        }
                        if(['text'].includes(item.type)){
                            return <div className="mb-3">
                                    <Field
                                        name={`template_content_${item.id}`}
                                        type="text"
                                        disabled={loading}
                                        component={InputComponent}
                                        label={item.label}
                                        formName={formName}
                                        handleDispatch={dispatch}
                                        
                                    />
                                </div>
                        }
                        if(['image'].includes(item.type)){
                            return <div className="mb-3">
                                    <Field
                                        name={`template_content_${item.id}`}
                                        type="text"
                                        disabled={loading}
                                        component={FilePickerComponent}
                                        label={item.label}
                                        height={"150px"}
                                        objectFit="cover"
                                        formName={formName}
                                        handleDispatch={dispatch}
                                    />
                                </div>
                        }
                    })
                }
            }else{
                contentEls = <div className="mb-3">
                                <Field
                                    name="content"
                                    type="text"
                                    disabled={loading}
                                    component={RTEComponent}
                                    label=""
                                    formName={formName}
                                    handleDispatch={dispatch}
                                    
                                />
                            </div>
            }
            return contentEls
        }
        return <></>
    }

    useEffect(()=>{
        // //console.log(137, props)
        setLoading(props.loading)
        if(props.formReset != formResetTime){
            //console.log(props.formReset)
            setFormResetTime(props.formReset)
        }
    }, [props])

    useEffect(()=>{
        handlResetForm()
    }, [formResetTime])


    return <>
        <form className="m-0" onChange={handleChangeForm} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12">
                    <FormSection sectionHeading={props.formTitle} sectionDesc="">
                        <div className="mb-3">
                            <Field
                                required={true}
                                name="title"
                                type="text"
                                disabled={loading}
                                component={InputComponent}
                                slugField={'slug'}
                                label="Tên danh mục"
                                formName={formName}
                                handleDispatch={dispatch}
                            />
                        </div>
                        <div className="mb-3">
                            <Field
                                required={true}
                                name="slug"
                                type="text"
                                disabled={loading}
                                component={InputComponent}
                                label="Đường dẫn tĩnh"
                                formName={formName}
                                handleDispatch={dispatch}
                                prefix="/"
                                // affix=".html"
                            />
                        </div>
                        <div className="mb-3">
                            <Field
                                required={true}
                                name="seo_title"
                                type="text"
                                disabled={loading}
                                component={InputComponent}
                                label="SEO Title"
                                formName={formName}
                                handleDispatch={dispatch}
                            />
                        </div>
                        <div className="mb-3">
                            <Field
                                required={true}
                                name="seo_description"
                                type="text"
                                disabled={loading}
                                component={InputComponent}
                                label="SEO description"
                                formName={formName}
                                handleDispatch={dispatch}
                            />
                        </div>
                        <div className="mb-3">
                            <Field
                                required={false}
                                name="seo_keyword"
                                type="text"
                                disabled={loading}
                                component={InputComponent}
                                label="SEO Keywords"
                                formName={formName}
                                handleDispatch={dispatch}
                            />
                        </div>
                        <div className="mb-3">
                            <Field
                                required={false}
                                name="seo_image"
                                type="text"
                                disabled={loading}
                                component={FilePickerComponent}
                                label="SEO image"
                                height={"150px"}
                                objectFit="cover"
                                formName={formName}
                                handleDispatch={dispatch}
                            />
                        </div>


                    </FormSection>


                    <FormSection type={'summary-detail'} sectionHeading="Nâng cao" sectionDesc="">

                        <div className="mb-3">
                            <Field
                                required={false}
                                name="excerpt"
                                disabled={loading}
                                component={TextAreaComponent}
                                label="Mô tả ngắn"
                                formName={formName}
                                handleDispatch={dispatch}
                            />
                        </div>
                        <div className="mb-3">
                            <Field
                                required={true}
                                name="template"
                                type="radio"
                                dataSet={renderDataSet()}
                                // defaultValue="none"
                                disabled={loading}
                                component={ImageOptionComponent}
                                label="Mãu giao diện"
                                formName={formName}
                                handleDispatch={dispatch}
                            />
                        </div>
                        {renderContent()}
                        <div className="mb-3">
                            <Field
                                required={false}
                                name="cover_image"
                                type="text"
                                disabled={loading}
                                component={FilePickerComponent}
                                label="Ảnh cover"
                                height={"150px"}
                                objectFit="cover"
                                formName={formName}
                                handleDispatch={dispatch}
                            />
                        </div>
                        <div className="mb-3">
                            <Field
                                required={false}
                                name="featured_image"
                                type="text"
                                disabled={loading}
                                component={FilePickerComponent}
                                label="Ảnh đại diện"
                                height={"150px"}
                                objectFit="cover"
                                formName={formName}
                                handleDispatch={dispatch}
                            />
                        </div>
                    </FormSection>

                    {formData?.values?.seo_title &&
                    <div className="mb-4 pb-4 d-none">
                        <SEOPreview
                            title={formData?.values?.seo_title}
                            description={formData?.values?.seo_description}
                            url={'domain.com/'+formData?.values?.slug+'.html'}
                            image={formData?.values?.seo_image}
                        />
                    </div>}
                    <div className="form-buttons">
                        <button disabled={!dirty} type="submit" className="btn btn-create"><i className="fa-solid fa-check"></i> Lưu cập nhật</button>
                        <span onClick={handlResetForm}><a>Nhập lại</a></span>
                    </div>
                </div>
            </div>
            
        </form>
    </>
}

export default reduxForm<{}, CustomProps>({
    form: formName
})(CategoryForm)