import { useEffect, useState, useMemo, useRef } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { requestFetchMessage } from "../store/actions"
import { updatePaginate } from "core/store/actions";
import { reset } from 'redux-form';
import API from "core/networks/API";
import { route } from "core/router";
import { API_ADMIN_MESSAGE, API_ADMIN_MESSAGE_DOWNLOAD } from "./../api";
import { formatDateTime } from "core/utils/helper";
import useBeforeUnload from './useBeforeUnload'

const download = (data: string, filename: string) => {
  // Pass the string to a Blob and turn it
  // into an ObjectURL
  const blob = new Blob([data], { type: "text/plain" });
  const jsonObjectUrl = URL.createObjectURL(blob);

  // Create an anchor element, set it's
  // href to be the Object URL we have created
  // and set the download property to be the file name
  // we want to set
  const anchorEl = document.createElement("a");
  anchorEl.href = jsonObjectUrl;
  anchorEl.download = filename;

  // There is no need to actually attach the DOM
  // element but we do need to click on it
  anchorEl.click();

  // We don't want to keep a reference to the file
  // any longer so we release it manually
  URL.revokeObjectURL(jsonObjectUrl);
}

export const useDownloadMessage = (conversation: any, autoDownload: boolean = false) => {
  const { id: conversation_id } = useParams()
  const [ loading, setLoading ] = useState(false)
  useBeforeUnload(loading)
  const canDownload = useRef(false)

  useEffect(() => {
    if (conversation) {
      canDownload.current = false
    } else {
      canDownload.current = false
    }
    setLoading(false)
  }, [conversation])

  useEffect(() => {
    (window as any)._download = download;

    return () => {
      (window as any)._download = undefined
    }
  }, [])

  const fetchListAndDownload = async (isClosePageAfterDownload: boolean = false) => {
    const res = await API.get(route(API_ADMIN_MESSAGE, conversation_id), {
      page_size: 0
    })

    const user: any = Object.values(conversation.data)
      .find((e: any) => e.account_type === 'USER')
    const merchant: any = Object.values(conversation.data)
      .find((e: any) => e.account_type === 'MERCHANT')

    if (res?.data?.data && canDownload.current) {
      // Turn the JSON object into a string
      const data = res.data.data.map((message: any) => {
        const messType = message.owner_message.account_type
        let name = messType === 'MERCHANT' ? merchant.shops[0].name : user.name
        let phone = messType === 'MERCHANT' ? merchant.phone : user.phone
        let attachments = ''
        if (message.attachments.length) {
          attachments = '[Attachments]: ' + message.attachments
            .map((e: any) => e.url.original)
            .join(', ')
        }
        const time = formatDateTime(message.created_at)
        const namePhone = `${name} - ${phone}`
        const body = message.body?.replace(/\n/g, " ") || ''

        return `[${time}] ${namePhone}: ${body} ${attachments}`
      })
      const filename = `${user.name} - ${merchant.shops[0].name}.txt`
      if ((window as any)._download) {
        (window as any)._download(data.join('\n'), filename)
      }
      // download(data.join('\n'), filename)
      setLoading(false)
      closePageAfterDownload()
    }
  }

  const closePageAfterDownload = (isClosePageAfterDownload: boolean = false, timeout: number = 1000) => {
    if (isClosePageAfterDownload) {
      setTimeout(() => {
        window.close()
      }, timeout) // close page after xxx ms
    }
  }

  const downloadFileFromServer = async (isClosePageAfterDownload: boolean = false) => {
    const res = await API.get(route(API_ADMIN_MESSAGE_DOWNLOAD, conversation_id), {})

    if (res?.data?.data) {
      fetch(res?.data?.data)
        .then((response: any) => response.text())
        .then((res: any) => {
          console.log(res);
        })
      // download(data.join('\n'), filename)
      setLoading(false)
      closePageAfterDownload()
    }
  }

  useEffect(() => {
    if (autoDownload && conversation.data?.length) {
      downloadMessage(true)
    }
  }, [autoDownload, conversation])

  const downloadMessage = (isClosePageAfterDownload: boolean = false) => {
    setLoading(true)
    canDownload.current = true
    fetchListAndDownload(isClosePageAfterDownload)
    // downloadFileFromServer(isClosePageAfterDownload)

    return true
  }

  return [ loading, downloadMessage ]
}
