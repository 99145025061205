import Lottie from "lottie-react";
import loadingFile1 from "core/assets/lotties/loading1.json"
import loadingFile2 from "core/assets/lotties/loading2.json"
import loadingFile3 from "core/assets/lotties/loading3.json"
import loadingFile4 from "core/assets/lotties/loading4.json"
import loadingFile5 from "core/assets/lotties/loading5.json"
import loadingFile6 from "core/assets/lotties/loading6.json"
import loadingFile7 from "core/assets/lotties/loading7.json"

const loadingFile: any = {
    'type1': loadingFile1,
    'type2': loadingFile2,
    'type3': loadingFile3,
    'type4': loadingFile4,
    'type5': loadingFile5,
    'type6': loadingFile6,
    'type7': loadingFile7
}

const Loading = (props: any) =>{
    return <div className="loading-component">
        <div className="loading">
            <Lottie animationData={loadingFile[props.type || 'type1']} loop={true} />
        </div>
    </div>
}

export default Loading;