import SkeletonComponent from "core/components/Skeleton/skeleton";

interface OverviewStatisticProps {
  dataOverview: any;
}

const OverviewStatistic = ({ dataOverview }: OverviewStatisticProps) => {
  console.log('dataOverview', dataOverview);
  const {data, loading } = dataOverview;
  return (
    <div className="col-12 m-0 mb-2 p-3 bg-white">
      <p>
        <label htmlFor="">Total Signup users</label>:{" "}
        {loading ? (
          <SkeletonComponent width={70} height={15} />
        ) : (
          <strong>{data.users}</strong>
        )}
      </p>
      <p>
        <label htmlFor="">Total Signup merchants</label>:{" "}
        {loading ? (
          <SkeletonComponent width={70} height={15} />
        ) : (
          <strong>{data.merchants}</strong>
        )}
      </p>
      <p>
        <label htmlFor="">Total Properties</label>:{" "}
        {loading ? (
          <SkeletonComponent width={70} height={15} />
        ) : (
          <strong>{data.properties}</strong>
        )}
      </p>
      <p>
        <label htmlFor="">Total bids</label>:{" "}
        {loading ? (
          <SkeletonComponent width={70} height={15} />
        ) : (
          <strong>{data.bids}</strong>
        )}
      </p>
      <p>
        <label htmlFor="">Total completed properties</label>:{" "}
        {loading ? (
          <SkeletonComponent width={70} height={15} />
        ) : (
          <strong>{data.completed_properties}</strong>
        )}
      </p>
      <p>
        <label htmlFor="">Total expired properties</label>:{" "}
        {loading ? (
          <SkeletonComponent width={70} height={15} />
        ) : (
          <strong>{data.ended_properties}</strong>

        )}
      </p>
    </div>
  );
};

export default OverviewStatistic;
