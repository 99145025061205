import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RouterName} from "../../router";
import {config} from "../../config/app";
import {deviceStorage} from "../../utils/devicestorage";
import { useCustomNavigate } from "core/utils/helper";
import Splash from "Splash";

const PrivateRouter = (props: any) => {
    const navigate = useCustomNavigate();
    const authRedux = useSelector((state: any) => state.Auth);

    useEffect(()=>{
        if([ "REQUEST_FETCH_AUTH_FAIL", "REQUEST_LOGOUT"].includes(authRedux.type)) {
            navigate(`${config.baseName}/login?referer=${window.location.pathname}`)
        }
    }, [authRedux.type])


    useEffect(()=>{
        const token = deviceStorage[config.appStorage].getItem(config.appPackage+'_X_TOKEN')
        //console.log(token)
        if(!token) {
            //console.log(token)
            navigate(`${config.baseName}/login`)
        }
    }, [])


    return ["REQUEST_LOGIN_SUCCESS", "REQUEST_FETCH_AUTH_SUCCESS"].includes(authRedux.type) ? <>{props.children}</> : <><Splash/></>
}

export default PrivateRouter