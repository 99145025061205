import { useState } from "react";
import ReactApexChart from "react-apexcharts";

interface CustomProps {
  filterName: string;
}

const StatisticChart = (props: any) => {

  const[dataChart, setDataChart] = useState({
    series: [{
      name: 'Users',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
    }, {
      name: 'Merchants',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
    }, {
      name: 'Posts',
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return "$ " + val + " thousands"
          }
        }
      }
    } as any,
  })

  return (
    <div className="col-12 m-0 mb-2 p-3 bg-white">
      <div id="chart">
        <ReactApexChart
          options={dataChart.options}
          series={dataChart.series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default StatisticChart
