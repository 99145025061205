import { useEffect, useRef, useState } from "react"
import { change } from "redux-form"
import Select from 'react-select'
import _ from "lodash";

const SelectComponent = (props: any) => {

    const [value, setValue] = useState<any>([])

    const handleChange = (e: any) => {
        setValue(e)
    }

    const getItem = (val: string) => {
        const [el] = _.filter(props.dataSet, (item) => {
            return item.value === props.meta.initial
        })
        // console.log(21, el)
        return el;
    }

    useEffect(()=>{
        // console.log(5, props.meta.initial, props.dataSet)
        if(props.meta.initial || props.meta.initial === ''){
            const el = getItem(props.meta.initial)
            if(el) setValue(el)
        }
            // setValue(JSON.parse(props.meta.initial))
    }, [])

    useEffect(()=>{
        //console.log(19, value)
        props.handleDispatch(change(props.formName, props.input.name, JSON.stringify(value)))
    }, [value])

    return <>
        <div className="custom-input custom-select2">
            <label>{props.label} {props.required && <span className="required">*</span>}</label>
            <Select
                closeMenuOnSelect={true}
                // defaultValue={}
                value={value}
                isMulti={props.isMulti}
                options={props.dataSet}
                onChange={handleChange}
            />
            <input {...props.input} disabled={props.disabled} readOnly={props.readOnly} type={props.type}/>
            {props.meta.error && <p className="error">{props.meta.error}</p>}
            {props.meta.warning && <p className="warning">{props.meta.warning}</p>}
        </div>
    </>
}

export default SelectComponent
