import {useEffect} from "react";
import {useCustomNavigate} from "../utils/helper";

const Gateway = (props: any) => {
    const navigate = useCustomNavigate()
    useEffect(() => {
        navigate('/dashboard');
    }, []);

    return <></>
}

// @ts-ignore
export default Gateway