import {ReactNode, useEffect, useState} from "react";
import _ from "lodash";

interface KDialogProps {
    show: boolean,
    title?: string,
    children?: string | ReactNode,
    onSubmit?: any,
    submitBtn?: string,
    onCancel?: any,
    cancelBtn?: string,
    icon?: ReactNode,
    variant?: string,
    id?: string,
    className?: string,
    overlay?: boolean
}

const KDialog = (props: KDialogProps) => {
    const [show, setShow] = useState(props.show)

    useEffect(()=>{
        if(props.overlay){
            const overlayElement:any = document.getElementById('overlay')
            if(props.show){
                overlayElement.style.display = 'block'
            }
            else{
                overlayElement.style.display = 'none'
            }
        }
        setShow(props.show)
    }, [props.show])

    return <>
        {show &&
        <div className={`kdialog ${props.variant || ''}`}>
            <div className="kdialog-container">
                {props.icon && <div className="kdialog-icon">{props.icon}</div>}
                <div className="kdialog-content">
                    {props.title && <div className="kdialog-header"><h3>{props.title}</h3></div>}
                    <div className="kdialog-body">
                        {props.children}
                    </div>
                    {(props.onCancel || props.onSubmit) && <div className="kdialog-actions text-end">
                        {props.onCancel && <button onClick={props.onCancel} className="btn btn-cancel">{props.cancelBtn}</button>}
                        {props.onSubmit && <button onClick={props.onSubmit} className="btn btn-submit">{props.submitBtn}</button>}
                    </div>}
                </div>
            </div>
        </div>
        }
    </>
}

export default KDialog