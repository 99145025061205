import {useEffect} from "react";
import moment from "moment";
import {formatDateTime, highlightKeywords, useCustomNavigate} from "../../../../core/utils/helper";
import {RouterName} from "../../../../core/router";
import {useSelector} from "react-redux";

const CouponHistoryListRowTemplate = (props: any) => {
    const navigate = useCustomNavigate()
    const filterRedux = useSelector((state: any) => state.form['couponHistoryMgmt']?.values)
    // //console.log(filterRedux)
    useEffect(() => {
    }, []);

    useEffect(() => {
    }, [props]);

    const renderStateLabel = (label: string) => {
        switch (label) {
            case 'AVAILABLE':
                return <span className="badge rounded-pill bg-info">AVAILABLE</span>
            case 'USED':
                return <span className="badge rounded-pill bg-success">USED</span>
            case 'VALIDATING':
                return <span className="badge rounded-pill bg-warning">VALIDATING</span>
            case 'USING':
                return <span className="badge rounded-pill bg-warning">USING</span>
            case 'EXPIRED':
                return <span className="badge rounded-pill bg-secondary">EXPIRED</span>
            case 'ACTIVED':
                return <span className="badge rounded-pill bg-warning">ACTIVED</span>
            default:
                return <></>
        }
    }

    const renderCell = (key: any) => {
        switch (key) {
            case 'user.phone':
                return <><div dangerouslySetInnerHTML={{__html: highlightKeywords(props.data.user.phone, filterRedux?.phone)}}></div>
                    </>
            case 'merchant':
                return <>{props.data.approved_at ? <>{props.data.merchant?.name}</> : '--'}</>
            case 'issued_by':
                return <>{props.data.issued_by?.username}</>
            case 'issued_at':
            case 'expired_at':
            case 'validated_at':
            case 'actived_at':
            case 'approved_at':
            case 'updated_at':
                return props.data[key] ? <>{formatDateTime(props.data[key])}</> : <>--</>
            case 'state':
                return <>{renderStateLabel(props.data.state_label)}</>
            case 'coupon_code':
                return <><div dangerouslySetInnerHTML={{__html: highlightKeywords(props.data[key], filterRedux?.coupon)}}></div></>
            default:
                return <><div dangerouslySetInnerHTML={{__html: highlightKeywords(props.data[key], filterRedux?.phone)}}></div></>
        }
    }

    const handlePreview = () => {
        window.open(`/${props.data?.slug?.alias}.html`, '_blank')
    }
    const handleEdit = () => {
        props.onEdit(props.data)
    }
    const handleDelete = () => {
        if(props.onDelete) {
            props.onDelete([props.data.id])
        }
    }

    return <tr onDoubleClick={handleEdit}>
        <td width={150}>
            <label>
                <input type={"checkbox"} value={props.data.id} checked={props.checked} onChange={props.onCheck}/> #{props.data.id}
            </label>
        </td>
        {
            props.columns.length > 0 && props.columns.map((col: any, k: number) => {
                if(col.state) return <td key={k}>{renderCell(col.key)}</td>
            })
        }
        <td className="text-end">
            <div className="actions">
                {/*<a onClick={handlePreview}><i className="fa-solid fa-eye"></i> <span>Xem</span></a>*/}
                {/*<a onClick={handleEdit}><i className="fa-solid fa-pen-to-square"></i> <span>Edit</span></a>*/}
                {/*<a onClick={handleDelete}><i className="fa-solid fa-trash-can"></i> <span>Re</span></a>*/}
            </div>
        </td>
    </tr>
}
export default CouponHistoryListRowTemplate
